import React, { Component } from "react";
import "./../style.scss";
import first from "../../assets/First-Form/first.png";
import second from "../../assets/First-Form/second.png";
import third from "../../assets/First-Form/third.png";
import fourth from "../../assets/First-Form/fourth.png";
import fifth from "../../assets/First-Form/fifth.png";
import sixth from "../../assets/First-Form/sixth.png";
import seventh from "../../assets/First-Form/seventh.png";
import eighth from "../../assets/First-Form/eighth.png";
import "../../assets/css/bootstrap4.css";
import timeLine from "../../assets/Welcome/icon_Time_line.png";
import answers from "../../assets/Welcome/icon_Answers_line.png";
import hcp from "../../assets/Welcome/icon_HCP_line.png";
import childIcon from "../../assets/Welcome/Child-icon.svg";
import adultsIcon from "../../assets/Welcome/Adults-icon.svg";
import homebg from "../../assets/Welcome/home_bg.png";
import logo from "../../assets/Welcome/logo.png";
import Cookies from "universal-cookie";
import "../../assets/css/bootstrap.css";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import ReactAutoComplete from "../../components/RctAutoComplete";

const cookies = new Cookies();

class index extends React.Component {
  state = {
    readingBookArry: [
      {
        id: "1",
        question: "Reading a Book",
        answer: "0",
      },
      {
        id: "2",
        question: "Reading a Book",
        answer: "1",
      },
      {
        id: "3",
        question: "Reading a Book",
        answer: "2",
      },
      {
        id: "4",
        question: "Reading a Book",
        answer: "3",
      },
    ],
    watchingTvArry: [
      {
        id: "1",
        question: "Watching Tv",
        answer: "0",
      },
      {
        id: "2",
        question: "Watching Tv",
        answer: "1",
      },
      {
        id: "3",
        question: "Watching Tv",
        answer: "2",
      },
      {
        id: "4",
        question: "Watching Tv",
        answer: "3",
      },
    ],
    publicPlace: [
      {
        id: "1",
        question:
          "Sitting still in a public place (e.g.a theartre, a cinema or a meeting)",
        answer: "0",
      },
      {
        id: "2",
        question:
          "Sitting still in a public place (e.g.a theartre, a cinema or a meeting)",
        answer: "1",
      },
      {
        id: "3",
        question:
          "Sitting still in a public place (e.g.a theartre, a cinema or a meeting)",
        answer: "2",
      },
      {
        id: "4",
        question:
          "Sitting still in a public place (e.g.a theartre, a cinema or a meeting)",
        answer: "3",
      },
    ],
    carPessenger: [
      {
        id: "1",
        question: "As a passenger in a car for an hour without a break",
        answer: "0",
      },
      {
        id: "2",
        question: "As a passenger in a car for an hour without a break",
        answer: "1",
      },
      {
        id: "3",
        question: "As a passenger in a car for an hour without a break",
        answer: "2",
      },
      {
        id: "4",
        question: "As a passenger in a car for an hour without a break",
        answer: "3",
      },
    ],
    restSleep: [
      {
        id: "1",
        question:
          "Lying down to rest in the afternoon when the circumstances allow",
        answer: "0",
      },
      {
        id: "2",
        question:
          "Lying down to rest in the afternoon when the circumstances allow",
        answer: "1",
      },
      {
        id: "3",
        question:
          "Lying down to rest in the afternoon when the circumstances allow",
        answer: "2",
      },
      {
        id: "4",
        question:
          "Lying down to rest in the afternoon when the circumstances allow",
        answer: "3",
      },
    ],
    talkingArray: [
      {
        id: "1",
        question: "Sitting and talking to someone",
        answer: "0",
      },
      {
        id: "2",
        question: "Sitting and talking to someone",
        answer: "1",
      },
      {
        id: "3",
        question: "Sitting and talking to someone",
        answer: "2",
      },
      {
        id: "4",
        question: "Sitting and talking to someone",
        answer: "3",
      },
    ],
    drunkArry: [
      {
        id: "1",
        question: "Sitting quietly after lunch without having drunk alcohol",
        answer: "0",
      },
      {
        id: "2",
        question: "Sitting quietly after lunch without having drunk alcohol",
        answer: "1",
      },
      {
        id: "3",
        question: "Sitting quietly after lunch without having drunk alcohol",
        answer: "2",
      },
      {
        id: "4",
        question: "Sitting quietly after lunch without having drunk alcohol",
        answer: "3",
      },
    ],
    carTrafficArry: [
      {
        id: "1",
        question: "In a car or bus white stopped for a few minutes in traffic",
        answer: "0",
      },
      {
        id: "2",
        question: "In a car or bus white stopped for a few minutes in traffic",
        answer: "1",
      },
      {
        id: "3",
        question: "In a car or bus white stopped for a few minutes in traffic",
        answer: "2",
      },
      {
        id: "4",
        question: "In a car or bus white stopped for a few minutes in traffic",
        answer: "3",
      },
    ],

    tabFirst: false,
    tabSecond: false,
    tabThird: false,
    tabFourth: false,
    tabFive: false,
    tabSixth: false,
    tabSeven: false,
    tabEight: false,

    sleepQuizArry: [],

    selectedFirstArry: [],
    selectedSecondArry: [],
    selectedThirdArry: [],
    selectedFourArry: [],
    selectedFiveArry: [],
    selectedSixArry: [],
    selectedSevenArry: [],
    selectedEightArry: [],

    isResultShow: false,

    slectedReadingBookValue: "",
    slectedWatchingTvValue: "",
    slectedWatchingPublicPlace: "",
    slectedCarPassenger: "",
    slectedRestSleep: "",
    slectedTalking: "",
    selectedDrunk: "",
    selectedCarTrafic: "",
  };

  handleReadingBook(obj) {
    const { selectedFirstArry } = this.state;

    const index = selectedFirstArry.indexOf(obj);

    selectedFirstArry.splice(index, 1);
    if (index > -1) {
    } else {
      selectedFirstArry.push(obj);
    }
    this.setState({
      selectedFirstArry: selectedFirstArry,
      tabFirst: true,
      slectedReadingBookValue: obj.answer,
    });

    // const { sleepQuizArry } = this.state;
    // sleepQuizArry.push(obj)
    // this.setState({ sleepQuizArry: sleepQuizArry, tabFirst: true })
  }

  handleWatchingTv(obj) {
    const { selectedSecondArry } = this.state;

    const index = selectedSecondArry.indexOf(obj);

    selectedSecondArry.splice(index, 1);
    if (index > -1) {
    } else {
      selectedSecondArry.push(obj);
    }
    this.setState({
      selectedSecondArry: selectedSecondArry,
      tabSecond: true,
      slectedWatchingTvValue: obj.answer,
    });

    // const { sleepQuizArry } = this.state;
    // sleepQuizArry.push(obj)
    // this.setState({ sleepQuizArry: sleepQuizArry, tabSecond: true })
  }

  handlePublicPlace(obj) {
    const { selectedThirdArry } = this.state;

    const index = selectedThirdArry.indexOf(obj);

    selectedThirdArry.splice(index, 1);
    if (index > -1) {
    } else {
      selectedThirdArry.push(obj);
    }
    this.setState({
      selectedThirdArry: selectedThirdArry,
      tabThird: true,
      slectedWatchingPublicPlace: obj.answer,
    });
    // const { sleepQuizArry } = this.state;
    // sleepQuizArry.push(obj)
    // this.setState({ sleepQuizArry: sleepQuizArry, tabThird: true })
  }

  handleCarPassenger(obj) {
    const { selectedFourArry } = this.state;

    const index = selectedFourArry.indexOf(obj);

    selectedFourArry.splice(index, 1);
    if (index > -1) {
    } else {
      selectedFourArry.push(obj);
    }
    this.setState({
      selectedFourArry: selectedFourArry,
      tabFourth: true,
      slectedCarPassenger: obj.answer,
    });

    // const { sleepQuizArry } = this.state;
    // sleepQuizArry.push(obj)
    // this.setState({ sleepQuizArry: sleepQuizArry, tabFourth: true })
  }

  handleRestSleep(obj) {
    const { selectedFiveArry } = this.state;

    const index = selectedFiveArry.indexOf(obj);

    selectedFiveArry.splice(index, 1);
    if (index > -1) {
    } else {
      selectedFiveArry.push(obj);
    }
    this.setState({
      selectedFiveArry: selectedFiveArry,
      tabFive: true,
      slectedRestSleep: obj.answer,
    });
    // const { sleepQuizArry } = this.state;
    // sleepQuizArry.push(obj)
    // this.setState({ sleepQuizArry: sleepQuizArry, tabFive: true })
  }

  handleTalking(obj) {
    const { selectedSixArry } = this.state;

    const index = selectedSixArry.indexOf(obj);

    selectedSixArry.splice(index, 1);
    if (index > -1) {
    } else {
      selectedSixArry.push(obj);
    }
    this.setState({
      selectedSixArry: selectedSixArry,
      tabSixth: true,
      slectedTalking: obj.answer,
    });

    // const { sleepQuizArry } = this.state;
    // sleepQuizArry.push(obj)
    // this.setState({ sleepQuizArry: sleepQuizArry, tabSixth: true })
  }

  handleDrunk(obj) {
    // const { sleepQuizArry } = this.state;
    // sleepQuizArry.push(obj)
    // this.setState({ sleepQuizArry: sleepQuizArry, tabSeven: true })

    const { selectedEightArry, isResultShow } = this.state;

    const index = selectedEightArry.indexOf(obj);

    selectedEightArry.splice(index, 1);
    if (index > -1) {
    } else {
      selectedEightArry.push(obj);
    }
    this.setState({
      selectedEightArry: selectedEightArry,
      tabSeven: true,
      isResultShow: true,
      selectedDrunk: obj.answer,
    });
  }

  handleCarTraffic(obj) {
    const { selectedSevenArry, sleepQuizArry } = this.state;
    const { history } = this.props;
    const {
      alcoholScoreData,
      selectedFirstArry,
      selectedSecondArry,
      selectedThirdArry,
      selectedFourArry,
      selectedFiveArry,
      selectedSixArry,
      selectedEightArry,
    } = this.state;

    const index = selectedSevenArry.indexOf(obj);

    selectedSevenArry.splice(index, 1);
    if (index > -1) {
    } else {
      selectedSevenArry.push(obj);
    }
    this.setState(
      {
        selectedSevenArry: selectedSevenArry,
        tabEight: true,
        selectedCarTrafic: obj.answer,
      },
      () => {
        sleepQuizArry.push(selectedFirstArry);
        sleepQuizArry.push(selectedSecondArry);
        sleepQuizArry.push(selectedThirdArry);
        sleepQuizArry.push(selectedFourArry);

        sleepQuizArry.push(selectedFiveArry);

        sleepQuizArry.push(selectedSixArry);

        sleepQuizArry.push(selectedSevenArry);
        sleepQuizArry.push(selectedEightArry);

        cookies.set("sleepQuizArry", sleepQuizArry);
        cookies.set("alcoholResult", false);
        cookies.remove("alcoholScoreData");
      }
    );
  }

  componentDidMount() {
    document.title = "Sleep | Test Screening";
  }

  ViewResult() {
    const { history } = this.props;
    history.push("/quiz-result");
  }

  render() {
    const { history } = this.props;
    const {
      fields,
      sleepQuizArry,
      watchingTvArry,
      carTrafficArry,
      readingBookArry,
      drunkArry,
      talkingArray,
      publicPlace,
      restSleep,
      carPessenger,
      tabFirst,
      tabSecond,
      tabThird,
      tabFourth,
      tabFive,
      tabSixth,
      tabSeven,
      tabEight,

      isResultShow,
      selectedSevenArry,

      slectedReadingBookValue,
      slectedWatchingTvValue,
      slectedWatchingPublicPlace,
      slectedCarPassenger,
      slectedRestSleep,
      slectedTalking,
      selectedDrunk,
      selectedCarTrafic,
    } = this.state;

    return (
      <div>
        <section>
          <div class="area-text-hidden">
            <div id="" class="arear-text-hidden-div" style={{ minHeight: 250 }}>
              <div id="header" class="col-xs-12">
                <div class="">
                  <div class="layout-outer">
                    <div class="layout-inner">
                      <div class="col-xs-12 col-md-12 default-style">
                        <a
                          id="960044208"
                          style={{ visibility: "hidden" }}
                          aria-hidden="true"
                        ></a>
                        <div class="box component section aria-hidden-text content-container header-container height-variant-1 first odd last col-xs-12 col-xs-offset-0 col-md-12 col-md-offset-0">
                          <div class="component-content" id="01995300884">
                            <div class="paragraphSystem content">
                              <a
                                id="01700272810"
                                style={{ visibility: "hidden" }}
                                aria-hidden="true"
                              ></a>
                              <div class="snippetReference component section default-style first odd col-xs-6 reference-brand-area">
                                <div class="inner">
                                  <div class="component-content">
                                    <a
                                      id="0597899827"
                                      style={{ visibility: "hidden" }}
                                      aria-hidden="true"
                                    ></a>
                                    <div class="richTextEnhanced richText section richTextHyperlinkedImage default-style first odd last component">
                                      <div class="component-content">
                                        <a href="/home">
                                          {" "}
                                          <img
                                            width="180"
                                            src={logo}
                                            class="richText-image pull-left"
                                            alt=""
                                          />
                                        </a>
                                        <div class="richText-content">
                                          <ph></ph>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="Header paragraphSystem"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="layout-outer p-0">
                  <div class="layout-inner">
                    <div class="col-xs-12 col-md-12 default-style">
                      <div class="Content paragraphSystem">
                        <a
                          id="777783402"
                          style={{ visibility: "hidden" }}
                          aria-hidden="true"
                        ></a>
                        <div class="box component section header-overlay first odd col-xs-12">
                          <div class="component-content" id="0789560808">
                            <div class="paragraphSystem content">
                              <a
                                id="01224270974"
                                style={{ visibility: "hidden" }}
                                aria-hidden="true"
                              ></a>
                              <div class="richText component section move-top main-header first odd col-xs-12">
                                <div class="component-content">
                                  <div class="richText-content">
                                    <h1>
                                      Welcome to the{" "}
                                      <span class="no-wrap">SleepCK</span>
                                    </h1>
                                  </div>
                                </div>
                              </div>
                              <div class="richText component section secondary-header even last col-xs-12">
                                <div class="component-content">
                                  <div class="richText-content">
                                    {/* <p><strong>Sleep is a common and treatable disease</strong></p>
                                                                        <p>It cannot be cured, but it can be managed and doesn´t have to slow you down</p> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container padding_30 custom-sleep-text">
            <p class="font-weight-bold f-18 ">Epworth Sleepiness Scale</p>

            <p class="">
              How likely are you to doze off or fall asleep in the following
              situations, in comparison to feeling just tired ? This refers to
              your usual way of life in recent times.
            </p>
            <p>
              Even if you have not done some of these things recently , try to
              work out how they would have affected you.
            </p>
            <p>
              Use the following scale to choose the most appropriate number for
              each situation:
            </p>
            <p class="mb-0">
              <strong>0=would never doze</strong>
            </p>
            <p class="mb-0">
              <strong>1=slight chance of dozing</strong>
            </p>
            <p class="mb-0">
              <strong>2=moderate chance of dozing</strong>
            </p>
            <p>
              <strong>3= high chance of dozing</strong>
            </p>
            <p>
              It's important that you answer all (8) question as best you can.
            </p>
            <div class="d-flex justify-content-between mt-5 custom-image-sec">
              <div class="situa text-center custom-read-img">
                <p class="font-weight-bold f-22 read-text">
                  <u> Situation</u>
                </p>
                <img src={first} class="" alt="" />
                <p class="mt-2 f-18">Reading a Book</p>
              </div>

              <div class="chance">
                <p class="font-weight-bold f-22">
                  <u> Chance of dozing</u>
                </p>

                <div class="dropdown mt-4">
                  <button
                    class="btn box-shadow dropdown border px-4"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    YOUR ANSWER
                    <span style={{ fontSize: "22px", fontWeight: "700" }}>
                      {" "}
                      {slectedReadingBookValue &&
                        ":  " + slectedReadingBookValue}{" "}
                    </span>
                  </button>
                  <div
                    class="dropdown-menu shdow-drop"
                    aria-labelledby="dropdownMenuButton"
                  >
                    {readingBookArry.map((obj) => (
                      <a
                        onClick={this.handleReadingBook.bind(this, obj)}
                        class="dropdown-item"
                      >
                        {obj.answer}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {tabFirst == true && (
              <div class="d-flex justify-content-between my-5 custom-image-sec">
                <div class="situa text-center">
                  <div class="second-img custom-second-img">
                    <img src={second} alt="" />
                    <p class="mt-2 f-18">Watching Tv</p>
                  </div>
                </div>
                <div class="chance">
                  <div class="dropdown mt-4">
                    <button
                      class="btn box-shadow dropdown border px-4"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      YOUR ANSWER
                      <span style={{ fontSize: "22px", fontWeight: "700" }}>
                        {" "}
                        {slectedWatchingTvValue &&
                          ":  " + slectedWatchingTvValue}{" "}
                      </span>
                    </button>
                    <div
                      class="dropdown-menu shdow-drop"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {watchingTvArry.map((obj) => (
                        <a
                          onClick={this.handleWatchingTv.bind(this, obj)}
                          class="dropdown-item"
                        >
                          {obj.answer}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {tabSecond == true && (
              <div class="d-flex justify-content-between my-5 custom-image-sec">
                <div class="">
                  <div class="second-img custom-read-img">
                    <img src={third} alt="" class="w-75" />
                    <p class="mt-2 f-18">
                      Sitting still in a public place
                      <br /> (e.g.a theartre, a cinema or a meeting)
                    </p>
                  </div>
                </div>
                <div class="chance">
                  <div class="dropdown mt-4">
                    <button
                      class="btn box-shadow dropdown border px-4"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      YOUR ANSWER
                      <span style={{ fontSize: "22px", fontWeight: "700" }}>
                        {" "}
                        {slectedWatchingPublicPlace &&
                          ":  " + slectedWatchingPublicPlace}{" "}
                      </span>
                    </button>
                    <div
                      class="dropdown-menu shdow-drop"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {publicPlace.map((obj) => (
                        <a
                          onClick={this.handlePublicPlace.bind(this, obj)}
                          class="dropdown-item"
                        >
                          {obj.answer}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {tabThird == true && (
              <div class="d-flex justify-content-between my-5 custom-image-sec">
                <div class="situa text-center">
                  <div class="second-img">
                    <img src={fourth} alt="" />
                    <p class="mt-2 f-18">
                      As a passenger in a car for an hour without a break
                    </p>
                  </div>
                </div>
                <div class="chance">
                  <div class="dropdown mt-4">
                    <button
                      class="btn box-shadow dropdown border px-4"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      YOUR ANSWER
                      <span style={{ fontSize: "22px", fontWeight: "700" }}>
                        {" "}
                        {slectedCarPassenger &&
                          ":  " + slectedCarPassenger}{" "}
                      </span>
                    </button>
                    <div
                      class="dropdown-menu shdow-drop"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {carPessenger.map((obj) => (
                        <a
                          onClick={this.handleCarPassenger.bind(this, obj)}
                          class="dropdown-item"
                        >
                          {obj.answer}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {tabFourth == true && (
              <div class="d-flex justify-content-between my-5 custom-image-sec">
                <div class="situa text-center">
                  <div class="second-img">
                    <img src={fifth} alt="" />
                    <p class="mt-2 f-18">
                      Lying down to rest in the afternoon when the circumstances
                      allow
                    </p>
                  </div>
                </div>
                <div class="chance">
                  <div class="dropdown mt-4">
                    <button
                      class="btn box-shadow dropdown border px-4"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      YOUR ANSWER
                      <span style={{ fontSize: "22px", fontWeight: "700" }}>
                        {" "}
                        {slectedRestSleep && ":  " + slectedRestSleep}{" "}
                      </span>
                    </button>
                    <div
                      class="dropdown-menu shdow-drop"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {restSleep.map((obj) => (
                        <a
                          onClick={this.handleRestSleep.bind(this, obj)}
                          class="dropdown-item"
                        >
                          {obj.answer}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {tabFive == true && (
              <div class="d-flex justify-content-between my-5 custom-image-sec">
                <div class="situa text-center">
                  <div class="second-img">
                    <img src={sixth} alt="" />
                    <p class="mt-2 f-18">Sitting and talking to someone</p>
                  </div>
                </div>
                <div class="chance">
                  <div class="dropdown mt-4">
                    <button
                      class="btn box-shadow dropdown border px-4"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      YOUR ANSWER
                      <span style={{ fontSize: "22px", fontWeight: "700" }}>
                        {" "}
                        {slectedTalking && ":  " + slectedTalking}{" "}
                      </span>
                    </button>
                    <div
                      class="dropdown-menu shdow-drop"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {talkingArray.map((obj) => (
                        <a
                          onClick={this.handleTalking.bind(this, obj)}
                          class="dropdown-item"
                        >
                          {obj.answer}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {tabSixth == true && (
              <div class="d-flex justify-content-between my-5 custom-image-sec">
                <div class="situa text-center">
                  <div class="second-img">
                    <img src={seventh} alt="" />
                    <p class="mt-2 f-18">
                      Sitting quietly after lunch without having drunk alcohol{" "}
                    </p>
                  </div>
                </div>
                <div class="chance">
                  <div class="dropdown mt-4">
                    <button
                      class="btn box-shadow dropdown border px-4"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      YOUR ANSWER
                      <span style={{ fontSize: "22px", fontWeight: "700" }}>
                        {" "}
                        {selectedDrunk && ":  " + selectedDrunk}{" "}
                      </span>
                    </button>
                    <div
                      class="dropdown-menu shdow-drop"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {drunkArry.map((obj) => (
                        <a
                          onClick={this.handleDrunk.bind(this, obj)}
                          class="dropdown-item"
                        >
                          {obj.answer}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {tabSeven == true && (
              <div class="d-flex justify-content-between my-5 custom-image-sec">
                <div class="situa text-center">
                  <div class="second-img">
                    <img src={eighth} alt="" />
                    <p class="mt-2 f-18">
                      In a car or bus white stopped <br />
                      for a few minutes in traffic
                    </p>
                  </div>
                </div>
                <div class="chance">
                  <div class="dropdown mt-4">
                    <button
                      class="btn box-shadow dropdown border px-4"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      YOUR ANSWER
                      <span style={{ fontSize: "22px", fontWeight: "700" }}>
                        {" "}
                        {selectedCarTrafic && ":  " + selectedCarTrafic}{" "}
                      </span>
                    </button>
                    <div
                      class="dropdown-menu shdow-drop"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {carTrafficArry.map((obj) => (
                        <a
                          onClick={this.handleCarTraffic.bind(this, obj)}
                          class="dropdown-item"
                        >
                          {obj.answer}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {selectedSevenArry.length > 0 && (
              <div class="btn richText text-show-action-view see_result">
                <a onClick={this.ViewResult.bind(this)} class="call-to-action">
                  See Result
                </a>
              </div>
            )}
          </div>

          <div id="footer" class="col-xs-12 px-0">
            <div class="row">
              <div class="layout-outer p-0">
                <div class="layout-inner">
                  <div class="col-xs-12 col-md-12 default-style">
                    <div
                      class="box component section default-style first odd last col-xs-12"
                      style={{ padding: 0 }}
                    >
                      <div class="component-content" id="0808245730">
                        <div class="paragraphSystem content">
                          <div class="snippetReference component section footer-section footer-section-legals even last col-xs-12 reference-footer-legals-2">
                            <div class="inner">
                              <div class="component-content">
                                <div class="richText component section footer-paragraphs odd">
                                  <div class="component-content">
                                    <div class="richText-content">
                                      <p>
                                        © 2023 My HealthCK. All rights reserved.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="Footer paragraphSystem"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <script
          src="https://code.jquery.com/jquery-3.2.1.slim.min.js"
          integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN"
          crossorigin="anonymous"
        ></script>
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js"
          integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q"
          crossorigin="anonymous"
        ></script>
        <script
          src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"
          integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl"
          crossorigin="anonymous"
        ></script>
      </div>
    );
  }
}

export default index;
