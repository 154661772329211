import React, { Component } from "react";
import "./../style.scss";
import one from "../../assets/Second-Form/1.png";
import two from "../../assets/Second-Form/2.png";
import three from "../../assets/Second-Form/3.png";
import logo from "../../assets/Welcome/logo.png";
import logoOne from "../../assets/Second-Form/logo.jpg";

import timeLine from "../../assets/Welcome/icon_Time_line.png";
import answers from "../../assets/Welcome/icon_Answers_line.png";
import hcp from "../../assets/Welcome/icon_HCP_line.png";
import childIcon from "../../assets/Welcome/Child-icon.svg";
import adultsIcon from "../../assets/Welcome/Adults-icon.svg";
import homebg from "../../assets/Welcome/home_bg.png";
import "../../assets/css/bootstrap4.css";

import "../../assets/css/bootstrap.css";
import Cookies from "universal-cookie";

const cookies = new Cookies();

class index extends React.Component {
  state = {
    questionOne: [
      {
        id: "1",
        answer: "Never",
        question: "How often do you have a drink containing alcohol?",
        points: "0",
      },
      {
        id: "1",
        answer: "Monthly or less",
        question: "How often do you have a drink containing alcohol?",
        points: "1",
      },
      {
        id: "1",
        answer: "2 to 4 times per month",
        question: "How often do you have a drink containing alcohol?",
        points: "2",
      },
      {
        id: "1",
        answer: "2 to 4 times per week",
        question: "How often do you have a drink containing alcohol?",
        points: "3",
      },
      {
        id: "1",
        answer: "4 or more times per week",
        question: "How often do you have a drink containing alcohol?",
        points: "4",
      },
    ],
    questionTwo: [
      {
        id: "1",
        answer: "0 to 2",
        question:
          "How many units of alcohol do you drink on a typical day when you are drinking?",
        points: "0",
      },
      {
        id: "1",
        answer: "3 to 4",
        question:
          "How many units of alcohol do you drink on a typical day when you are drinking?",
        points: "1",
      },
      {
        id: "1",
        answer: "5 to 6",
        question:
          "How many units of alcohol do you drink on a typical day when you are drinking?",
        points: "2",
      },
      {
        id: "1",
        answer: "7 to 9",
        question:
          "How many units of alcohol do you drink on a typical day when you are drinking?",
        points: "3",
      },
      {
        id: "1",
        answer: "10 or more",
        question:
          "How many units of alcohol do you drink on a typical day when you are drinking?",
        points: "4",
      },
    ],
    questionThree: [
      {
        id: "1",
        answer: "Never",
        question:
          "How often have you had 6 or more units if female, or 8 or more if male, on a single occasion in the last year?",
        points: "0",
      },
      {
        id: "1",
        answer: "Less than monthly",
        question:
          "How often have you had 6 or more units if female, or 8 or more if male, on a single occasion in the last year?",
        points: "1",
      },
      {
        id: "1",
        answer: "Monthly",
        question:
          "How often have you had 6 or more units if female, or 8 or more if male, on a single occasion in the last year?",
        points: "2",
      },
      {
        id: "1",
        answer: "Weekly",
        question:
          "How often have you had 6 or more units if female, or 8 or more if male, on a single occasion in the last year?",
        points: "3",
      },
      {
        id: "1",
        answer: "Daily or almost daily",
        question:
          "How often have you had 6 or more units if female, or 8 or more if male, on a single occasion in the last year?",
        points: "4",
      },
    ],
    questionFour: [
      {
        id: "1",
        answer: "Never",
        question:
          "How often during the last year have you found that you were not able to stop drinking once you had started?",
        points: "0",
      },
      {
        id: "1",
        answer: "Less than monthly",
        question:
          "How often during the last year have you found that you were not able to stop drinking once you had started?",
        points: "1",
      },
      {
        id: "1",
        answer: "monthly",
        question:
          "How often during the last year have you found that you were not able to stop drinking once you had started?",
        points: "2",
      },
      {
        id: "1",
        answer: "weekly",
        question:
          "How often during the last year have you found that you were not able to stop drinking once you had started?",
        points: "3",
      },
      {
        id: "1",
        answer: "Daily or almost daily",
        question:
          "How often during the last year have you found that you were not able to stop drinking once you had started?",
        points: "4",
      },
    ],
    questionFive: [
      {
        id: "1",
        answer: "Never",
        question:
          " How often during the last year have you failed to do what was normally expected from you because of your drinking? ",
        points: "0",
      },
      {
        id: "1",
        answer: "Less than monthly",
        question:
          " How often during the last year have you failed to do what was normally expected from you because of your drinking? ",
        points: "1",
      },
      {
        id: "1",
        answer: "monthly",
        question:
          " How often during the last year have you failed to do what was normally expected from you because of your drinking? ",
        points: "2",
      },
      {
        id: "1",
        answer: "weekly",
        question:
          " How often during the last year have you failed to do what was normally expected from you because of your drinking? ",
        points: "3",
      },
      {
        id: "1",
        answer: "Daily or almost daily",
        question:
          " How often during the last year have you failed to do what was normally expected from you because of your drinking? ",
        points: "4",
      },
    ],

    questionSix: [
      {
        id: "1",
        answer: "Never",
        question:
          "How often during the last year have you needed an alcoholic drink in the morning to get yourself going after a heavy drinking session?",
        points: "0",
      },
      {
        id: "1",
        answer: "Less than monthly",
        question:
          "How often during the last year have you needed an alcoholic drink in the morning to get yourself going after a heavy drinking session?",
        points: "1",
      },
      {
        id: "1",
        answer: "monthly",
        question:
          "How often during the last year have you needed an alcoholic drink in the morning to get yourself going after a heavy drinking session?",
        points: "2",
      },
      {
        id: "1",
        answer: "weekly",
        question:
          "How often during the last year have you needed an alcoholic drink in the morning to get yourself going after a heavy drinking session?",
        points: "3",
      },
      {
        id: "1",
        answer: "Daily or almost daily",
        question:
          "How often during the last year have you needed an alcoholic drink in the morning to get yourself going after a heavy drinking session?",
        points: "4",
      },
    ],

    questionSeven: [
      {
        id: "1",
        answer: "Never",
        question:
          "How often during the last year have you had a feeling of guilt or remorse after drinking?",
        points: "0",
      },
      {
        id: "1",
        answer: "Less than monthly",
        question:
          "How often during the last year have you had a feeling of guilt or remorse after drinking?",
        points: "1",
      },
      {
        id: "1",
        answer: "monthly",
        question:
          "How often during the last year have you had a feeling of guilt or remorse after drinking?",
        points: "2",
      },
      {
        id: "1",
        answer: "weekly",
        question:
          "How often during the last year have you had a feeling of guilt or remorse after drinking?",
        points: "3",
      },
      {
        id: "1",
        answer: "Daily or almost daily",
        question:
          "How often during the last year have you had a feeling of guilt or remorse after drinking?",
        points: "4",
      },
    ],
    questionEight: [
      {
        id: "1",
        answer: "Never",
        question:
          "How often during the last year have you been unable to remember what happened the night before because you had been drinking?",
        points: "0",
      },
      {
        id: "1",
        answer: "Less than monthly",
        question:
          "How often during the last year have you been unable to remember what happened the night before because you had been drinking?",
        points: "1",
      },
      {
        id: "1",
        answer: "monthly",
        question:
          "How often during the last year have you been unable to remember what happened the night before because you had been drinking?",
        points: "2",
      },
      {
        id: "1",
        answer: "weekly",
        question:
          "How often during the last year have you been unable to remember what happened the night before because you had been drinking?",
        points: "3",
      },
      {
        id: "1",
        answer: "Daily or almost daily",
        question:
          "How often during the last year have you been unable to remember what happened the night before because you had been drinking?",
        points: "4",
      },
    ],

    questionNine: [
      {
        id: "1",
        answer: "No",
        question:
          "Have you or somebody else been injured as a result of your drinking?",
        points: "0",
      },
      {
        id: "1",
        answer: "",
        question:
          "Have you or somebody else been injured as a result of your drinking?",
        points: "1",
      },
      {
        id: "1",
        answer: "Yes,but not in the last year",
        question:
          "Have you or somebody else been injured as a result of your drinking?",
        points: "2",
      },
      {
        id: "1",
        answer: "",
        question:
          "Have you or somebody else been injured as a result of your drinking?",
        points: "3",
      },
      {
        id: "1",
        answer: "Yes,during the last year",
        question:
          "Have you or somebody else been injured as a result of your drinking?",
        points: "4",
      },
    ],
    questionTen: [
      {
        id: "1",
        answer: "No",
        question:
          "Has a relative or friend, doctor or other health worker been concerned about your drinking or suggested that you cut down?",
        points: "0",
      },
      {
        id: "1",
        answer: "",
        question:
          "Has a relative or friend, doctor or other health worker been concerned about your drinking or suggested that you cut down?",
        points: "1",
      },
      {
        id: "1",
        answer: "Yes,but not in the last year",
        question:
          "Has a relative or friend, doctor or other health worker been concerned about your drinking or suggested that you cut down?",
        points: "2",
      },
      {
        id: "1",
        answer: "",
        question:
          "Has a relative or friend, doctor or other health worker been concerned about your drinking or suggested that you cut down?",
        points: "3",
      },
      {
        id: "1",
        answer: "Yes,during the last year",
        question:
          "Has a relative or friend, doctor or other health worker been concerned about your drinking or suggested that you cut down?",
        points: "4",
      },
    ],

    answerOne: "",
    answerTwo: "",
    answerThree: "",
    answerFour: "",
    answerFive: "",
    answerSix: "",
    answerSeven: "",
    answerEight: "",
    answerNine: "",
    answerTen: "",

    alcoholScoreData: [],

    selectedFirstArry: [],
    selectedSecondArry: [],
    selectedThirdArry: [],
    selectedFourArry: [],
    selectedFiveArry: [],
    selectedSixArry: [],
    selectedSevenArry: [],
    selectedEightArry: [],
    selectedNineArry: [],
    selectedTenArry: [],

    isNext: false,
  };

  componentDidMount() {
    document.title = "Alcohol | Test Screening";
  }

  handleQuesOne(obj, e) {
    const { selectedFirstArry } = this.state;

    const index = selectedFirstArry.indexOf(obj);

    selectedFirstArry.splice(index, 1);
    if (index > -1) {
    } else {
      selectedFirstArry.push(obj);
    }
    this.setState({
      selectedFirstArry: selectedFirstArry,
      answerOne: obj.points,
    });
  }

  handleQuesTwo(obj) {
    const { selectedSecondArry } = this.state;

    const index = selectedSecondArry.indexOf(obj);

    selectedSecondArry.splice(index, 1);
    if (index > -1) {
    } else {
      selectedSecondArry.push(obj);
    }
    this.setState({
      selectedSecondArry: selectedSecondArry,
      answerTwo: obj.points,
    });
  }

  handleQuesThree(obj) {
    const { alcoholScoreData } = this.state;

    const { selectedThirdArry } = this.state;

    const index = selectedThirdArry.indexOf(obj);

    selectedThirdArry.splice(index, 1);
    if (index > -1) {
    } else {
      selectedThirdArry.push(obj);
    }
    this.setState({
      selectedThirdArry: selectedThirdArry,
      answerThree: obj.points,
    });
  }

  handleQuesFour(obj) {
    const { alcoholScoreData } = this.state;

    const { selectedFourArry } = this.state;

    const index = selectedFourArry.indexOf(obj);

    selectedFourArry.splice(index, 1);
    if (index > -1) {
    } else {
      selectedFourArry.push(obj);
    }
    this.setState({
      selectedFourArry: selectedFourArry,
      answerFour: obj.points,
    });
  }

  handleQuesFive(obj) {
    const { alcoholScoreData } = this.state;

    const { selectedFiveArry } = this.state;

    const index = selectedFiveArry.indexOf(obj);

    selectedFiveArry.splice(index, 1);
    if (index > -1) {
    } else {
      selectedFiveArry.push(obj);
    }
    this.setState({
      selectedFiveArry: selectedFiveArry,
      answerFive: obj.points,
    });
  }

  handleQuesSix(obj) {
    const { alcoholScoreData } = this.state;

    const { selectedSixArry } = this.state;

    const index = selectedSixArry.indexOf(obj);

    selectedSixArry.splice(index, 1);
    if (index > -1) {
    } else {
      selectedSixArry.push(obj);
    }
    this.setState({ selectedSixArry: selectedSixArry, answerSix: obj.points });
  }

  handleQuesSeven(obj) {
    const { alcoholScoreData } = this.state;

    const { selectedSevenArry } = this.state;

    const index = selectedSevenArry.indexOf(obj);

    selectedSevenArry.splice(index, 1);
    if (index > -1) {
    } else {
      selectedSevenArry.push(obj);
    }
    this.setState({
      selectedSevenArry: selectedSevenArry,
      answerSeven: obj.points,
    });
  }

  handleQuesEight(obj) {
    const { alcoholScoreData } = this.state;

    const { selectedEightArry } = this.state;

    const index = selectedEightArry.indexOf(obj);

    selectedEightArry.splice(index, 1);
    if (index > -1) {
    } else {
      selectedEightArry.push(obj);
    }
    this.setState({
      selectedEightArry: selectedEightArry,
      answerEight: obj.points,
    });
  }

  handleQuesNine(obj) {
    const { alcoholScoreData } = this.state;

    const { selectedTenArry } = this.state;

    const index = selectedTenArry.indexOf(obj);

    selectedTenArry.splice(index, 1);
    if (index > -1) {
    } else {
      selectedTenArry.push(obj);
    }
    this.setState({ selectedTenArry: selectedTenArry, answerNine: obj.points });
  }

  handleQuesTen(obj) {
    const { alcoholScoreData } = this.state;

    const { selectedNineArry } = this.state;

    const index = selectedNineArry.indexOf(obj);

    selectedNineArry.splice(index, 1);
    if (index > -1) {
    } else {
      selectedNineArry.push(obj);
    }
    this.setState({
      selectedNineArry: selectedNineArry,
      answerTen: obj.points,
    });
  }

  handleResult() {
    const { history } = this.props;
    history.push("/quiz-result");
    const {
      alcoholScoreData,
      selectedFirstArry,
      selectedSecondArry,
      selectedThirdArry,
      selectedFourArry,
      selectedFiveArry,
      selectedSixArry,
      selectedSevenArry,
      selectedEightArry,
      selectedNineArry,
      selectedTenArry,
    } = this.state;

    alcoholScoreData.push(selectedFirstArry);
    alcoholScoreData.push(selectedSecondArry);
    alcoholScoreData.push(selectedThirdArry);
    alcoholScoreData.push(selectedFourArry);

    alcoholScoreData.push(selectedFiveArry);

    alcoholScoreData.push(selectedSixArry);

    alcoholScoreData.push(selectedSevenArry);
    alcoholScoreData.push(selectedEightArry);
    alcoholScoreData.push(selectedNineArry);
    alcoholScoreData.push(selectedTenArry);

    cookies.set("alcoholResult", true);
    cookies.set("alcoholScoreData", alcoholScoreData);
    cookies.remove("sleepQuizArry");
    // history.push("/Home");
  }

  handleNext() {
    this.setState({ isNext: true });
  }

  render() {
    const { history } = this.props;
    const {
      fields,
      errors,
      questionOne,
      questionTwo,
      questionThree,
      questionFour,
      questionFive,
      questionSix,
      questionSeven,
      questionEight,
      questionNine,
      questionTen,
      answerOne,
      answerTwo,
      answerThree,
      answerFour,
      answerFive,
      answerSix,
      answerSeven,
      answerEight,
      answerNine,
      answerTen,
      selectedThirdArry,
      selectedFirstArry,
      selectedSecondArry,
      isNext,

      selectedFourArry,
      selectedFiveArry,
      selectedSixArry,
      selectedSevenArry,
      selectedEightArry,
      selectedNineArry,
      selectedTenArry,
    } = this.state;

    let auditCScore = NaN;
    if (
      isNaN(parseInt(answerOne) + parseInt(answerTwo) + parseInt(answerThree))
    ) {
      auditCScore = 0;
    } else {
      auditCScore =
        parseInt(answerOne) + parseInt(answerTwo) + parseInt(answerThree);
    }

    let auditTotalScore = NaN;
    if (
      isNaN(
        parseInt(answerFour) +
          parseInt(answerFive) +
          parseInt(answerSix) +
          parseInt(answerSeven) +
          parseInt(answerEight) +
          parseInt(answerNine) +
          parseInt(answerTen)
      )
    ) {
      auditTotalScore = 0;
    } else {
      auditTotalScore =
        parseInt(answerFour) +
        parseInt(answerFive) +
        parseInt(answerSix) +
        parseInt(answerSeven) +
        parseInt(answerEight) +
        parseInt(answerNine) +
        parseInt(answerTen);
    }

    return (
      <div>
        <body style={{ padding: "0px", margin: "0px" }}>
          <div class="area-text-hidden">
            <div id="" class="arear-text-hidden-div" style={{ minHeight: 250 }}>
              <div id="header" class="col-xs-12">
                <div class="">
                  <div class="layout-outer">
                    <div class="layout-inner">
                      <div class="col-xs-12 col-md-12 default-style">
                        <a
                          id="960044208"
                          style={{ visibility: "hidden" }}
                          aria-hidden="true"
                        ></a>
                        <div class="box component section aria-hidden-text content-container header-container height-variant-1 first odd last col-xs-12 col-xs-offset-0 col-md-12 col-md-offset-0">
                          <div class="component-content" id="01995300884">
                            <div class="paragraphSystem content">
                              <a
                                id="01700272810"
                                style={{ visibility: "hidden" }}
                                aria-hidden="true"
                              ></a>
                              <div class="snippetReference component section default-style first odd col-xs-6 reference-brand-area">
                                <div class="inner">
                                  <div class="component-content">
                                    <a
                                      id="0597899827"
                                      style={{ visibility: "hidden" }}
                                      aria-hidden="true"
                                    ></a>
                                    <div class="richTextEnhanced richText section richTextHyperlinkedImage default-style first odd last component">
                                      <div class="component-content">
                                        <a href="/home">
                                          {" "}
                                          <img
                                            width="180"
                                            src={logo}
                                            class="richText-image pull-left"
                                            alt=""
                                          />
                                        </a>
                                        <div class="richText-content">
                                          <ph></ph>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="Header paragraphSystem"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="layout-outer p-0">
                  <div class="layout-inner">
                    <div class="col-xs-12 col-md-12 default-style">
                      <div class="Content paragraphSystem">
                        <a
                          id="777783402"
                          style={{ visibility: "hidden" }}
                          aria-hidden="true"
                        ></a>

                        <div class="box component section header-overlay first odd col-xs-12">
                          <div class="component-content" id="0789560808">
                            <div class="paragraphSystem content">
                              <a
                                id="01224270974"
                                style={{ visibility: "hidden" }}
                                aria-hidden="true"
                              ></a>
                              <div class="richText component section move-top main-header first odd col-xs-12">
                                <div class="component-content">
                                  <div class="richText-content">
                                    <h1>
                                      Welcome to the{" "}
                                      <span class="no-wrap">AlcoholCK</span>
                                    </h1>
                                  </div>
                                </div>
                              </div>
                              <div class="richText component section secondary-header even last col-xs-12">
                                <div class="component-content">
                                  <div class="richText-content">
                                    {/* <p class="f16-size"><strong>Alcohol is a common and treatable disease</strong></p>
                                                                        <p class="f16-size">It cannot be cured, but it can be managed and doesn´t have to slow you down</p> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <h2 class="custom-user-head" style={{ marginTop: 50 }}>
              Alcohol use disorders identification test consumption (AUDIT C)
            </h2>

            <p class="input-center">
              {" "}
              <img src={three} alt="" srcset="" width="50%" />
            </p>

            <p class="custom-user-text" style={{ marginTop: 40 }}>
              This alcohol harm assessment tool consists of the consumption
              questions from the full alcohol use disorders identification test
              (AUDIT).{" "}
            </p>

            <p>
              {" "}
              <p class="input-center">
                {" "}
                <img src={one} alt="" srcset="" width="50%" />
              </p>
            </p>

            {isNext == true ? (
              <div class="table-responsive">
                <h4 style={{ fontWeight: "bold" }}>What to do next</h4>
                <p>
                  If you have a score of 5 or more and time permits, complete
                  the remaining alcohol harm questions below to obtain a full
                  AUDIT score
                </p>

                <h2 style={{ marginTop: 50, fontWeight: "bold" }}>
                  Remaining AUDIT assessment questions
                </h2>

                <table class="table table-bordered mt-5 custom-title">
                  <tr>
                    <th class="th_background" colspan="2" rowspan="2">
                      Questions
                    </th>
                    <th colspan="5" class="logo th_background">
                      Scoring system
                    </th>
                    <th colspan="" rowspan="2" class="th_background">
                      Your score
                    </th>
                  </tr>

                  <tr style={{ textAlign: "center" }}>
                    <td colspan="">0</td>
                    <td colspan="">1</td>
                    <td colspan="">2</td>
                    <td>3</td>
                    <td>4</td>
                  </tr>

                  <tr>
                    <td colspan="2" className="px-1">
                      How often during the last year have you found that you
                      were not able to stop drinking once you had started?
                    </td>

                    {questionFour.map((obj) => (
                      <td colspan="1" className="logo custom-lable">
                        <input
                          onClick={this.handleQuesFour.bind(this, obj)}
                          type="radio"
                          id="male"
                          name="questionFour"
                          value={obj.answer}
                        />{" "}
                        <label for="Yes">{obj.answer}</label>
                      </td>
                    ))}
                    <td>
                      <input
                        value={answerFour}
                        class="text-position f22-size"
                        style={{ color: "#000" }}
                        disabled
                        type="text"
                        name=""
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colspan="2">
                      How often during the last year have you failed to do what
                      was normally expected from you because of your drinking?{" "}
                    </td>
                    {questionFive.map((obj) => (
                      <td colspan="1" class="logo custom-lable">
                        <input
                          onClick={this.handleQuesFive.bind(this, obj)}
                          type="radio"
                          id="male"
                          name="questionFive"
                          value={obj.answer}
                        />{" "}
                        <label for="Yes">{obj.answer}</label>
                      </td>
                    ))}

                    <td>
                      <input
                        value={answerFive}
                        class="text-position f22-size"
                        style={{ color: "#000" }}
                        disabled
                        type="text"
                        name=""
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colspan="2">
                      How often during the last year have you needed an
                      alcoholic drink in the morning to get yourself going after
                      a heavy drinking session?
                    </td>
                    {questionSix.map((obj) => (
                      <td colspan="1" class="logo custom-lable">
                        <input
                          onClick={this.handleQuesSix.bind(this, obj)}
                          type="radio"
                          id="male"
                          name="questionSix"
                          value={obj.answer}
                        />{" "}
                        <label for="Yes">{obj.answer}</label>
                      </td>
                    ))}
                    <td>
                      <input
                        value={answerSix}
                        class="text-position f22-size"
                        style={{ color: "#000" }}
                        disabled
                        type="text"
                        name=""
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colspan="2">
                      How often during the last year have you had a feeling of
                      guilt or remorse after drinking?
                    </td>
                    {questionSeven.map((obj) => (
                      <td colspan="1" class="logo custom-lable">
                        <input
                          onClick={this.handleQuesSeven.bind(this, obj)}
                          type="radio"
                          id="male"
                          name="questionSeven"
                          value={obj.answer}
                        />{" "}
                        <label for="Yes">{obj.answer}</label>
                      </td>
                    ))}
                    <td>
                      <input
                        value={answerSeven}
                        class="text-position f22-size"
                        style={{ color: "#000" }}
                        disabled
                        type="text"
                        name=""
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colspan="2">
                      How often during the last year have you been unable to
                      remember what happened the night before because you had
                      been drinking?
                    </td>

                    {questionEight.map((obj) => (
                      <td colspan="1" class="logo custom-lable">
                        <input
                          onClick={this.handleQuesEight.bind(this, obj)}
                          type="radio"
                          id="male"
                          name="questionEight"
                          value={obj.answer}
                        />{" "}
                        <label for="Yes">{obj.answer}</label>
                      </td>
                    ))}
                    <td>
                      <input
                        value={answerEight}
                        class="text-position f22-size"
                        style={{ color: "#000" }}
                        disabled
                        type="text"
                        name=""
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colspan="2">
                      Have you or somebody else been injured as a result of your
                      drinking?{" "}
                    </td>
                    {questionNine.map((obj) => (
                      <td colspan="1" class="logo custom-lable">
                        <input
                          onClick={this.handleQuesNine.bind(this, obj)}
                          type="radio"
                          id="male"
                          name="questionNine"
                          value={obj.answer}
                        />{" "}
                        <label for="Yes">{obj.answer}</label>
                      </td>
                    ))}
                    <td>
                      <input
                        value={answerNine}
                        class="text-position f22-size"
                        style={{ color: "#000" }}
                        disabled
                        type="text"
                        name=""
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colspan="2">
                      Has a relative or friend, doctor or other health worker
                      been concerned about your drinking or suggested that you
                      cut down?
                    </td>

                    {questionTen.map((obj) => (
                      <td colspan="1" class="logo custom-lable">
                        <input
                          onClick={this.handleQuesTen.bind(this, obj)}
                          type="radio"
                          id="male"
                          name="questionTen"
                          value={obj.answer}
                        />{" "}
                        <label for="Yes">{obj.answer}</label>
                      </td>
                    ))}
                    <td>
                      <input
                        value={answerTen}
                        class="text-position f22-size"
                        style={{ color: "#000" }}
                        disabled
                        type="text"
                        name=""
                      />
                    </td>
                  </tr>
                </table>

                <table
                  className="table table-bordered mt-3 documents center-table"
                  style={{ width: "40%", height: 50 }}
                >
                  <th style={{ border: 1 }}>
                    <h6 style={{ marginTop: 20 }}>Total AUDIT score</h6>
                  </th>
                  <th style={{ border: 1, width: "70%" }}>
                    <input
                      value={auditTotalScore}
                      class="text-position f22-size"
                      disabled
                      type="text"
                      name=""
                      style={{ color: "red" }}
                    />
                  </th>
                </table>

                <table class="table table-bordered">
                  <tr className="table-scoring-img-flex">
                    <td>
                      <ul style={{ listStyle: "none" }} className="space-none">
                        <li>
                          <h5 style={{ fontWeight: "bold", marginTop: 10 }}>
                            Scoring:
                          </h5>
                          <ul style={{ paddingLeft: 40 }}>
                            <li
                              class={
                                auditTotalScore > -1 && auditTotalScore < 8
                                  ? "bold-strong"
                                  : ""
                              }
                            >
                              0 to 7 indicates low risk
                            </li>
                            <li
                              class={
                                auditTotalScore > 7 && auditTotalScore < 16
                                  ? "bold-strong"
                                  : ""
                              }
                            >
                              8 to 15 indicates increasing risk
                            </li>
                            <li
                              class={
                                auditTotalScore > 15 && auditTotalScore < 20
                                  ? "bold-strong"
                                  : ""
                              }
                            >
                              16 to 19 indicates higher risk
                            </li>
                            <li
                              class={auditTotalScore >= 20 ? "bold-strong" : ""}
                            >
                              20 or more indicates possible dependence
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </td>

                    <td>
                      {" "}
                      <img src={three} alt="" srcset="" class="scoring_img" />
                    </td>
                  </tr>
                </table>
              </div>
            ) : (
              <div>
                <table class="table table-bordered datasize-change">
                  <tr>
                    <th class="th_background" colspan="2" rowspan="2">
                      Questions
                    </th>
                    <th colspan="5" class="logo th_background">
                      Scoring system
                    </th>
                    <th colspan="" rowspan="2" class="th_background">
                      Your score
                    </th>
                  </tr>

                  <tr style={{ textAlign: "center" }}>
                    <td colspan="">0</td>
                    <td colspan="">1</td>
                    <td colspan="">2</td>
                    <td>3</td>
                    <td>4</td>
                  </tr>

                  <tr>
                    <td colspan="2">
                      How often do you have a drink containing alcohol?{" "}
                    </td>
                    {questionOne.map((obj) => (
                      <td colspan="1" class="logo custom-lable">
                        <input
                          onClick={this.handleQuesOne.bind(this, obj)}
                          type="radio"
                          id="male"
                          name="questionOne"
                          value={obj.answer}
                        />{" "}
                        <label for="Yes">{obj.answer}</label>
                      </td>
                    ))}

                    <td>
                      <input
                        value={answerOne}
                        class="text-position f22-size"
                        disabled
                        type="text"
                        name=""
                        style={{ fontSize: "20", color: "#000" }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colspan="2">
                      How many units of alcohol do you drink on a typical day
                      when you are drinking?
                    </td>
                    {questionTwo.map((obj) => (
                      <td colspan="1" class="logo custom-lable">
                        <input
                          onClick={this.handleQuesTwo.bind(this, obj)}
                          type="radio"
                          id="male"
                          name="questionTwo"
                          value={obj.answer}
                        />{" "}
                        <label for="Yes">{obj.answer}</label>
                      </td>
                    ))}
                    <td>
                      <input
                        value={answerTwo}
                        class="text-position f22-size"
                        style={{ color: "#000" }}
                        disabled
                        type="text"
                        name=""
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colspan="2">
                      How often have you had 6 or more units if female, or 8 or
                      more if male, on a single occasion in the last year?
                    </td>

                    {questionThree.map((obj) => (
                      <td colspan="1" class="logo custom-lable">
                        <input
                          onClick={this.handleQuesThree.bind(this, obj)}
                          type="radio"
                          id="male"
                          name="questionThree"
                          value={obj.answer}
                        />{" "}
                        <label for="Yes">{obj.answer}</label>
                      </td>
                    ))}
                    <td>
                      <input
                        value={answerThree}
                        class="text-position f22-size"
                        style={{ color: "#000" }}
                        disabled
                        type="text"
                        name=""
                      />
                    </td>
                  </tr>
                </table>

                <table
                  class="table table-bordered mt-3 documents center-table"
                  style={{ width: "40%", height: 50 }}
                >
                  <th style={{ border: 1 }}>
                    <h6 style={{ marginTop: 20 }}>AUDIT C score</h6>
                  </th>
                  <th style={{ border: 1, width: "70%" }}>
                    <input
                      value={auditCScore}
                      class="text-position f22-size"
                      disabled
                      type="text"
                      name=""
                      style={{ color: "red" }}
                    />
                  </th>
                </table>

                <ul style={{ listStyle: "none" }}>
                  <li>
                    <h5 style={{ fontWeight: "bold", marginTop: 10 }}>
                      Scoring:
                    </h5>
                    <ul style={{ paddingLeft: 40 }}>
                      <li>A total of 5 or more is a positive screen</li>
                      <li
                        class={
                          auditCScore > -1 && auditCScore < 5
                            ? "bold-strong"
                            : ""
                        }
                      >
                        0 to 4 indicates low risk
                      </li>
                      <li
                        class={
                          auditCScore > 4 && auditCScore < 8
                            ? "bold-strong"
                            : ""
                        }
                      >
                        5 to 7 indicates increasing risk
                      </li>
                      <li
                        class={
                          auditCScore > 7 && auditCScore < 11
                            ? "bold-strong"
                            : ""
                        }
                      >
                        8 to10 indicates higher risk
                      </li>
                      <li
                        class={
                          auditCScore > 10 && auditCScore < 13
                            ? "bold-strong"
                            : ""
                        }
                      >
                        {" "}
                      </li>
                    </ul>
                  </li>
                </ul>

                {selectedThirdArry.length > 0 &&
                  selectedFirstArry.length > 0 &&
                  selectedSecondArry.length > 0 && (
                    <div class="richText component section  nav-arrow-right nav-button nav-button-arrow-right even last col-xs-12">
                      <div class="component-content">
                        <div class="richText-content text-center my-5 next_btn_set">
                          <p>
                            <a onClick={this.handleNext.bind(this)}>Next</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            )}
            {auditTotalScore > 0 && (
              <div>
                <h2 style={{ margintop: 50 }}>Alcohol unit reference</h2>
                <img src={logoOne} alt="" srcset="" width="100%" />
              </div>
            )}
          </div>
          <div id="footer" class="">
            {selectedFourArry.length > 0 &&
              selectedFiveArry.length > 0 &&
              selectedSixArry.length > 0 &&
              selectedSevenArry.length > 0 &&
              selectedEightArry.length > 0 &&
              selectedNineArry.length > 0 &&
              selectedTenArry.length > 0 && (
                <div class="richText component section  nav-arrow-right nav-button nav-button-arrow-right even last col-xs-12">
                  <div class="component-content">
                    <div class="richText-content text-center my-5 next_btn_set ">
                      <p>
                        <a onClick={this.handleResult.bind(this)}>See Result</a>
                      </p>
                    </div>
                  </div>
                </div>
              )}

            <div class="">
              <div class="layout-outer">
                <div class="layout-inner">
                  <div class="col-xs-12 col-md-12 default-style">
                    <div
                      class="box component section default-style first odd last col-xs-12"
                      style={{ padding: 0 }}
                    >
                      <div class="component-content" id="0808245730">
                        <div class="content">
                          <div class="snippetReference component section footer-section footer-section-legals even last col-xs-12 reference-footer-legals-2">
                            <div class="inner">
                              <div class="component-content">
                                <div class="richText component section footer-paragraphs odd">
                                  <div class="component-content">
                                    <div class="richText-content">
                                      <p>
                                        © 2023 My HealthCK. All rights reserved.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="Footer paragraphSystem"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </body>
      </div>
    );
  }
}

export default index;
