/**
 * Rct Page Loader
 */
import React from 'react';
import LinearProgress from "@material-ui/core/LinearProgress";

const RctPageLoader = () => (
    // <LinearProgress/>
    <>
    </>
);

export default RctPageLoader;
