import React, { Component } from "react";
import logo from "../../assets/Welcome/logo.png";

import child from "../../assets/Welcome/icon-Adult-Child-green (1).svg";
import adultChild from "../../assets/Welcome/Adult-child-icon.svg";
import childBg from "../../assets/Welcome/child_bg.png";
import "../../assets/css/bootstrap.css";
import Cookies from "universal-cookie";
import $ from "jquery";

const cookies = new Cookies();

class index extends React.Component {
  state = {
    childQuesOne: [
      {
        id: "1",
        title: "Very Bad",
        question: "How is your asthma today?",
        points: "0",
      },
      {
        id: "2",
        title: "Bad",
        question: "How is your asthma today?",
        points: "1",
      },
      {
        id: "3",
        title: "Good",
        question: "How is your asthma today?",
        points: "2",
      },
      {
        id: "4",
        title: "Very Good",
        question: "How is your asthma today?",
        points: "3",
      },
    ],
    childQuesTwo: [
      {
        id: "1",
        title: "It's a big problem, I can't do what I want to do.",
        question:
          "How much of a problem is your asthma when you run, exercise or play sports?",
        points: "0",
      },
      {
        id: "2",
        title: "It's a problem and I don't like it.",
        question:
          "How much of a problem is your asthma when you run, exercise or play sports?",
        points: "1",
      },
      {
        id: "3",
        title: "It's a bit of a problem but it's okay",
        question:
          "How much of a problem is your asthma when you run, exercise or play sports?",
        points: "2",
      },
      {
        id: "4",
        title: "It's not a problem",
        question:
          "How much of a problem is your asthma when you run, exercise or play sports?",
        points: "3",
      },
    ],
    childQuesThree: [
      {
        id: "1",
        title: "Yes, all the time.",
        question: "Does your asthma make you cough?",
        points: "0",
      },
      {
        id: "2",
        title: "Yes, most of the time.",
        question: "Does your asthma make you cough?",
        points: "1",
      },
      {
        id: "3",
        title: "Yes, sometimes.",
        question: "Does your asthma make you cough?",
        points: "2",
      },
      {
        id: "4",
        title: "No, never",
        question: "Does your asthma make you cough?",
        points: "3",
      },
    ],
    childQuesFour: [
      {
        id: "1",
        title: "Yes, all the time.",
        question: "Does your asthma make you wake up during the night?",
        points: "0",
      },
      {
        id: "2",
        title: "Yes, most of the time.",
        question: "Does your asthma make you wake up during the night?",
        points: "1",
      },
      {
        id: "3",
        title: "Yes, sometimes.",
        question: "Does your asthma make you wake up during the night?",
        points: "2",
      },
      {
        id: "4",
        title: "No, never",
        question: "Does your asthma make you wake up during the night?",
        points: "3",
      },
    ],

    childQuesFive: [
      {
        id: "1",
        title: "None",
        question:
          "During the last 4 weeks, how many days did your child have any daytime asthma symptoms ?",
        points: "5",
      },
      {
        id: "2",
        title: "1 to 3 days",
        question:
          "During the last 4 weeks, how many days did your child have any daytime asthma symptoms ?",
        points: "4",
      },
      {
        id: "3",
        title: "4 to 10 days",
        question:
          "During the last 4 weeks, how many days did your child have any daytime asthma symptoms ?",
        points: "3",
      },
      {
        id: "4",
        title: "11 to 18 days",
        question:
          "During the last 4 weeks, how many days did your child have any daytime asthma symptoms ?",
        points: "2",
      },
      {
        id: "4",
        title: "19 to 24 days",
        question:
          "During the last 4 weeks, how many days did your child have any daytime asthma symptoms ?",
        points: "1",
      },
      {
        id: "4",
        title: "Every day",
        question:
          "During the last 4 weeks, how many days did your child have any daytime asthma symptoms ?",
        points: "0",
      },
    ],

    childQuesSix: [
      {
        id: "1",
        title: "None",
        question:
          "During the last 4 weeks, how many days did your child wheeze during the day because of asthma?",
        points: "5",
      },
      {
        id: "2",
        title: "1 to 3 days",
        question:
          "During the last 4 weeks, how many days did your child wheeze during the day because of asthma?",
        points: "4",
      },
      {
        id: "3",
        title: "4 to 10 days",
        question:
          "During the last 4 weeks, how many days did your child wheeze during the day because of asthma?",
        points: "3",
      },
      {
        id: "4",
        title: "11 to 18 days",
        question:
          "During the last 4 weeks, how many days did your child wheeze during the day because of asthma?",
        points: "2",
      },
      {
        id: "4",
        title: "19 to 24 days",
        question:
          "During the last 4 weeks, how many days did your child wheeze during the day because of asthma?",
        points: "1",
      },
      {
        id: "4",
        title: "Every day",
        question:
          "During the last 4 weeks, how many days did your child wheeze during the day because of asthma?",
        points: "0",
      },
    ],
    childQuesSeven: [
      {
        id: "1",
        title: "None",
        question:
          "During the last 4 weeks, how many days did your child wake up during the night because of asthma?",
        points: "5",
      },
      {
        id: "2",
        title: "1 to 3 days",
        question:
          "During the last 4 weeks, how many days did your child wake up during the night because of asthma?",
        points: "4",
      },
      {
        id: "3",
        title: "4 to 10 days",
        question:
          "During the last 4 weeks, how many days did your child wake up during the night because of asthma?",
        points: "3",
      },
      {
        id: "4",
        title: "11 to 18 days",
        question:
          "During the last 4 weeks, how many days did your child wake up during the night because of asthma?",
        points: "2",
      },
      {
        id: "4",
        title: "19 to 24 days",
        question:
          "During the last 4 weeks, how many days did your child wake up during the night because of asthma?",
        points: "1",
      },
      {
        id: "4",
        title: "Every day",
        question:
          "During the last 4 weeks, how many days did your child wake up during the night because of asthma?",
        points: "0",
      },
    ],

    childFirstId: {},
    childSecondId: {},
    childThirdId: {},
    childFourthId: {},
    childFifthId: {},
    childSixthId: {},
    childSeventhId: {},

    childQuizArry: [],
    buttonCount: 1,
    buttonActive: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleQuesOne(obj) {
    const { childQuizArry } = this.state;
    this.setState({ childFirstId: obj.title, buttonActive: true });
    childQuizArry.push(obj);
    this.setState({ childQuizArry: childQuizArry });
  }

  handleQuesTwo(obj) {
    const { childQuizArry } = this.state;
    this.setState({ childSecondId: obj.title, buttonActive: true });
    childQuizArry.push(obj);
    this.setState({ childQuizArry: childQuizArry });
  }
  handleQuesThree(obj) {
    const { childQuizArry } = this.state;
    this.setState({ childThirdId: obj.title, buttonActive: true });
    childQuizArry.push(obj);
    this.setState({ childQuizArry: childQuizArry });
  }

  handleQuesFour(obj) {
    const { childQuizArry } = this.state;
    this.setState({ childFourthId: obj.title, buttonActive: true });
    childQuizArry.push(obj);
    this.setState({ childQuizArry: childQuizArry });
  }

  handleQuesFive(obj) {
    const { childQuizArry } = this.state;
    this.setState({ childFifthId: obj.title, buttonActive: true });
    childQuizArry.push(obj);
    this.setState({ childQuizArry: childQuizArry });
  }

  handleQuesSixth(obj) {
    const { childQuizArry } = this.state;
    this.setState({ childSixthId: obj.title, buttonActive: true });
    childQuizArry.push(obj);
    this.setState({ childQuizArry: childQuizArry });
  }

  handleQuesSeven(obj) {
    const { childQuizArry } = this.state;
    this.setState({ childSeventhId: obj.title, buttonActive: true });
    childQuizArry.push(obj);
    this.setState({ childQuizArry: childQuizArry });
  }

  handleNextQues() {
    const { buttonCount, buttonActive } = this.state;
    if (buttonActive == true || buttonCount === 5) {
      this.setState({ buttonCount: buttonCount + 1 }, () => {
        this.setState({ buttonActive: false });
      });
    }

    if (buttonCount === 8) {
      const { childQuizArry } = this.state;

      const { history } = this.props;
      cookies.set("childQuizz", childQuizArry);

      history.push("/result");
    }
  }

  backTo() {
    const { buttonCount } = this.state;
    if (buttonCount > 1) {
      this.setState({ buttonCount: buttonCount - 1 });
    }
    if (buttonCount < 2) {
      const { history } = this.props;
      history.push("/asthmack/child");
    }
  }

  render() {
    const { history } = this.props;
    const {
      fields,
      errors,
      buttonActive,
      childQuizArry,
      childQuesFour,
      childSecondId,
      childThirdId,
      childFourthId,
      childFifthId,
      childSixthId,
      childSeventhId,
      childQuesSix,
      childQuesThree,
      childQuesSeven,
      buttonCount,
      childQuesFive,
      childQuesTwo,
      childQuesOne,
      childFirstId,
    } = this.state;

    return (
      <body class="page-quiz page-children-quiz  layout-bootstrap-layout-12 template-branded-with-claims grid-bootstrap header-height-5 initialized">
        <div>
          <div class="row">
            <div id="header" class="col-xs-12">
              <div class="row">
                <div class="layout-outer">
                  <div class="layout-inner">
                    <div class="col-xs-12 col-md-12 default-style">
                      <a
                        id="960044208"
                        style={{ visibility: "hidden" }}
                        aria-hidden="true"
                      ></a>
                      <div class="box component section content-container header-container height-variant-1 first odd last col-xs-12 col-xs-offset-0 col-md-12 col-md-offset-0">
                        <div class="component-content" id="01995300884">
                          <div class="paragraphSystem content">
                            <a
                              id="01700272810"
                              style={{ visibility: "hidden" }}
                              aria-hidden="true"
                            ></a>
                            <div class="snippetReference component section default-style first odd col-xs-6 reference-brand-area">
                              <div class="inner">
                                <div class="component-content">
                                  <a
                                    id="0597899827"
                                    style={{ visibility: "hidden" }}
                                    aria-hidden="true"
                                  ></a>
                                  <div class="richTextEnhanced richText section richTextHyperlinkedImage default-style first odd last component">
                                    <div class="component-content">
                                      <a href="../../welcome.html">
                                        {" "}
                                        <img
                                          width="180"
                                          src={logo}
                                          class="richText-image pull-left"
                                          alt=""
                                        />
                                      </a>
                                      <div class="richText-content">
                                        <ph></ph>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="Header paragraphSystem"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="content" class="col-xs-12" style={{ minHeight: 827 }}>
              <div class="row">
                <div class="layout-outer">
                  <div class="layout-inner">
                    <div class="col-xs-12 col-md-12 default-style">
                      <div class="Content paragraphSystem">
                        <a
                          id="102865319"
                          style={{ visibility: "hidden" }}
                          aria-hidden="true"
                        ></a>
                        <div class="box component section header-overlay first odd col-xs-12">
                          <div class="component-content" id="0596876139">
                            <div class="paragraphSystem content"></div>
                          </div>
                        </div>
                        <div class="box component section content-container even last col-xs-12 col-xs-offset-0 col-md-12 col-md-offset-0">
                          <div class="component-content" id="567928183">
                            <div class="paragraphSystem content">
                              <a
                                id="01492979743"
                                style={{ visibility: "hidden" }}
                                aria-hidden="true"
                              ></a>
                              <div class="parametrizedhtml component section default-style first odd col-xs-12 reference-quiz-progress-bar">
                                <div class="component-content">
                                  <div class="quiz-progress-wrapper">
                                    <div class="progress-header">
                                      <div class="adult-and-child">
                                        <div class="progress-header-text">
                                          Adult and child
                                        </div>
                                        <div class="progress-outline"></div>
                                      </div>
                                      <div class="adult">
                                        <div class="progress-header-text">
                                          Adult
                                        </div>
                                        <div class="progress-outline"></div>
                                      </div>
                                    </div>
                                    <div class="progress-body">
                                      <div class="marks">
                                        <div
                                          class={
                                            buttonCount === 1
                                              ? "mark mark1 current "
                                              : buttonCount > 1
                                              ? "mark mark1 ok"
                                              : ""
                                          }
                                        >
                                          1
                                        </div>
                                        <div
                                          class={
                                            buttonCount === 2
                                              ? "mark mark2 current "
                                              : buttonCount > 2
                                              ? "mark mark2 ok"
                                              : "mark mark2"
                                          }
                                        >
                                          2
                                        </div>
                                        <div
                                          class={
                                            buttonCount === 3
                                              ? "mark mark3 current "
                                              : buttonCount > 3
                                              ? "mark mark3 ok"
                                              : "mark mark3"
                                          }
                                        >
                                          3
                                        </div>
                                        <div
                                          class={
                                            buttonCount === 4
                                              ? "mark mark4 current "
                                              : buttonCount > 4
                                              ? "mark mark4 ok"
                                              : "mark mark4"
                                          }
                                        >
                                          4
                                        </div>
                                        <div
                                          class={
                                            buttonCount === 6
                                              ? "mark mark5 current "
                                              : buttonCount > 5
                                              ? "mark mark5 ok"
                                              : "mark mark5"
                                          }
                                        >
                                          5
                                        </div>
                                        <div
                                          class={
                                            buttonCount === 7
                                              ? "mark mark6 current "
                                              : buttonCount > 6
                                              ? "mark mark6 ok"
                                              : "mark mark6"
                                          }
                                        >
                                          6
                                        </div>
                                        <div
                                          class={
                                            buttonCount === 8
                                              ? "mark mark7 current "
                                              : "mark mark7 "
                                          }
                                        >
                                          7
                                        </div>
                                      </div>
                                      <div class="bar">
                                        <div class="progress-line lvl1"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="quiz component section child-quiz even col-xs-12 initialized">
                                <div class="component quiz initialized">
                                  <div
                                    class="quizPrimaryContainer "
                                    data-quiz-type="redirect"
                                    data-score-display="true"
                                  >
                                    <div class="quiz questions initialized">
                                      <div class="questionsPaginationContainer">
                                        <div
                                          class="questionsPerPage"
                                          id="page1"
                                        >
                                          <div class="questionAnswers paragraphSystem">
                                            <a
                                              id="1160561637"
                                              style={{ visibility: "hidden" }}
                                              aria-hidden="true"
                                            ></a>

                                            {buttonCount === 1 ? (
                                              <div class="questionanswers component section act-box first odd">
                                                <div class="questionanswers-container">
                                                  <div class="quiz questionWithRadio component initialized questionview-1">
                                                    <div class="component-content">
                                                      <div class="quizQuestion">
                                                        <p class="quesLeftCaption">
                                                          Question 1
                                                        </p>
                                                        <p>
                                                          How is your asthma
                                                          today?
                                                        </p>
                                                        <p></p>
                                                        <div
                                                          class="imgContainer"
                                                          id="question0"
                                                        >
                                                          <div class="genericFormGroup very_bad_page">
                                                            {childQuesOne.map(
                                                              (obj) => (
                                                                <>
                                                                  <span class="formControl-radio">
                                                                    <input
                                                                      type="radio"
                                                                      id="child-1-answer4"
                                                                      data-weightage="3"
                                                                      name="child-1"
                                                                    />
                                                                  </span>
                                                                  <label
                                                                    onClick={this.handleQuesOne.bind(
                                                                      this,
                                                                      obj
                                                                    )}
                                                                    style={{
                                                                      backgroundColor:
                                                                        childFirstId ==
                                                                        obj.title
                                                                          ? "#358dbe"
                                                                          : "",

                                                                      color:
                                                                        childFirstId ==
                                                                        obj.title
                                                                          ? "#FFFFFF"
                                                                          : "",
                                                                    }}
                                                                    for="child-1-answer4"
                                                                  >
                                                                    {obj.title}
                                                                  </label>
                                                                </>
                                                              )
                                                            )}
                                                          </div>
                                                          <div
                                                            class="quizScoreBox"
                                                            id="question0score"
                                                          >
                                                            <p></p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="clear"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {buttonCount === 2 ? (
                                              <div class="questionanswers component section act-box even first odd">
                                                <div class="questionanswers-container">
                                                  <div class="quiz questionWithRadio component initialized questionview-2">
                                                    <div class="component-content">
                                                      <div class="quizQuestion">
                                                        <p class="quesLeftCaption">
                                                          Question 2
                                                        </p>
                                                        <p>
                                                          How much of a problem
                                                          is your asthma when
                                                          you run, exercise or
                                                          play sports?
                                                        </p>
                                                        <p></p>
                                                        <div
                                                          class="imgContainer box-row big_problem_question"
                                                          id="question1"
                                                        >
                                                          {childQuesTwo.map(
                                                            (obj) => (
                                                              <div class="genericFormGroup box-class">
                                                                <span class="formControl-radio">
                                                                  <input
                                                                    type="radio"
                                                                    id="child-2-answer1"
                                                                    data-weightage="0"
                                                                    name="child-2"
                                                                  />
                                                                </span>

                                                                <label
                                                                  onClick={this.handleQuesTwo.bind(
                                                                    this,
                                                                    obj
                                                                  )}
                                                                  style={{
                                                                    backgroundColor:
                                                                      childSecondId ==
                                                                      obj.title
                                                                        ? "#358dbe"
                                                                        : "",

                                                                    color:
                                                                      childSecondId ==
                                                                      obj.title
                                                                        ? "#FFFFFF"
                                                                        : "",
                                                                  }}
                                                                  for="child-1-answer4"
                                                                >
                                                                  {obj.title}
                                                                </label>
                                                              </div>
                                                            )
                                                          )}

                                                          <div
                                                            class="quizScoreBox"
                                                            id="question1score"
                                                          >
                                                            <p></p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="clear"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )}

                                            {buttonCount === 3 ? (
                                              <div class="questionanswers component section act-box even first odd">
                                                <div class="questionanswers-container">
                                                  <div class="quiz questionWithRadio component initialized questionview-2">
                                                    <div class="component-content">
                                                      <div class="quizQuestion">
                                                        <p class="quesLeftCaption">
                                                          Question 3
                                                        </p>
                                                        <p>
                                                          Does your asthma make
                                                          you cough?
                                                        </p>
                                                        <p></p>
                                                        <div
                                                          class="imgContainer box-row"
                                                          id="question1"
                                                        >
                                                          {childQuesThree.map(
                                                            (obj) => (
                                                              <div class="genericFormGroup box-class">
                                                                <span class="formControl-radio">
                                                                  <input
                                                                    type="radio"
                                                                    id="child-2-answer1"
                                                                    data-weightage="0"
                                                                    name="child-2"
                                                                  />
                                                                </span>

                                                                <label
                                                                  onClick={this.handleQuesThree.bind(
                                                                    this,
                                                                    obj
                                                                  )}
                                                                  style={{
                                                                    backgroundColor:
                                                                      childThirdId ==
                                                                      obj.title
                                                                        ? "#358dbe"
                                                                        : "",

                                                                    color:
                                                                      childThirdId ==
                                                                      obj.title
                                                                        ? "#FFFFFF"
                                                                        : "",
                                                                  }}
                                                                  for="child-1-answer4"
                                                                >
                                                                  {obj.title}
                                                                </label>
                                                              </div>
                                                            )
                                                          )}

                                                          <div
                                                            class="quizScoreBox"
                                                            id="question1score"
                                                          >
                                                            <p></p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="clear"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )}

                                            {buttonCount === 4 ? (
                                              <div class="questionanswers component section act-box even first odd">
                                                <div class="questionanswers-container">
                                                  <div class="quiz questionWithRadio component initialized questionview-2">
                                                    <div class="component-content">
                                                      <div class="quizQuestion">
                                                        <p class="quesLeftCaption">
                                                          Question 4
                                                        </p>
                                                        <p>
                                                          Does your asthma make
                                                          you wake up during the
                                                          night?
                                                        </p>
                                                        <p></p>
                                                        <div
                                                          class="imgContainer box-row"
                                                          id="question1"
                                                        >
                                                          {childQuesFour.map(
                                                            (obj) => (
                                                              <div class="genericFormGroup box-class">
                                                                <span class="formControl-radio">
                                                                  <input
                                                                    type="radio"
                                                                    id="child-2-answer1"
                                                                    data-weightage="0"
                                                                    name="child-2"
                                                                  />
                                                                </span>

                                                                <label
                                                                  onClick={this.handleQuesFour.bind(
                                                                    this,
                                                                    obj
                                                                  )}
                                                                  style={{
                                                                    backgroundColor:
                                                                      childFourthId ==
                                                                      obj.title
                                                                        ? "#358dbe"
                                                                        : "",

                                                                    color:
                                                                      childFourthId ==
                                                                      obj.title
                                                                        ? "#FFFFFF"
                                                                        : "",
                                                                  }}
                                                                  for="child-1-answer4"
                                                                >
                                                                  {obj.title}
                                                                </label>
                                                              </div>
                                                            )
                                                          )}

                                                          <div
                                                            class="quizScoreBox"
                                                            id="question1score"
                                                          >
                                                            <p></p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="clear"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )}

                                            {buttonCount === 6 ? (
                                              <div class="questionanswers component section act-box even first odd">
                                                <div class="questionanswers-container">
                                                  <div class="quiz questionWithRadio component initialized questionview-2">
                                                    <div class="component-content">
                                                      <div class="quizQuestion">
                                                        <p class="quesLeftCaption">
                                                          Question 5
                                                        </p>
                                                        <p>
                                                          During the last 4
                                                          weeks, how many days
                                                          did your child have
                                                          any daytime asthma
                                                          symptoms ?
                                                        </p>
                                                        <p></p>
                                                        <div
                                                          class="imgContainer box-row"
                                                          id="question1"
                                                        >
                                                          {childQuesFive.map(
                                                            (obj) => (
                                                              <div class="genericFormGroup box-class">
                                                                <span class="formControl-radio">
                                                                  <input
                                                                    type="radio"
                                                                    id="child-2-answer1"
                                                                    data-weightage="0"
                                                                    name="child-2"
                                                                  />
                                                                </span>

                                                                <label
                                                                  onClick={this.handleQuesFive.bind(
                                                                    this,
                                                                    obj
                                                                  )}
                                                                  style={{
                                                                    backgroundColor:
                                                                      childFifthId ==
                                                                      obj.title
                                                                        ? "#358dbe"
                                                                        : "",

                                                                    color:
                                                                      childFifthId ==
                                                                      obj.title
                                                                        ? "#FFFFFF"
                                                                        : "",
                                                                  }}
                                                                  for="child-1-answer4"
                                                                >
                                                                  {obj.title}
                                                                </label>
                                                              </div>
                                                            )
                                                          )}

                                                          <div
                                                            class="quizScoreBox"
                                                            id="question1score"
                                                          >
                                                            <p></p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="clear"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )}

                                            {buttonCount === 7 ? (
                                              <div class="questionanswers component section act-box even first odd">
                                                <div class="questionanswers-container">
                                                  <div class="quiz questionWithRadio component initialized questionview-2">
                                                    <div class="component-content">
                                                      <div class="quizQuestion">
                                                        <p class="quesLeftCaption">
                                                          Question 6
                                                        </p>
                                                        <p>
                                                          During the last 4
                                                          weeks, how many days
                                                          did your child wheeze
                                                          during the day because
                                                          of asthma?
                                                        </p>
                                                        <p></p>
                                                        <div
                                                          class="imgContainer box-row"
                                                          id="question1"
                                                        >
                                                          {childQuesSix.map(
                                                            (obj) => (
                                                              <div class="genericFormGroup box-class">
                                                                <span class="formControl-radio">
                                                                  <input
                                                                    type="radio"
                                                                    id="child-2-answer1"
                                                                    data-weightage="0"
                                                                    name="child-2"
                                                                  />
                                                                </span>

                                                                <label
                                                                  onClick={this.handleQuesSixth.bind(
                                                                    this,
                                                                    obj
                                                                  )}
                                                                  style={{
                                                                    backgroundColor:
                                                                      childSixthId ==
                                                                      obj.title
                                                                        ? "#358dbe"
                                                                        : "",

                                                                    color:
                                                                      childSixthId ==
                                                                      obj.title
                                                                        ? "#FFFFFF"
                                                                        : "",
                                                                  }}
                                                                  for="child-1-answer4"
                                                                >
                                                                  {obj.title}
                                                                </label>
                                                              </div>
                                                            )
                                                          )}

                                                          <div
                                                            class="quizScoreBox"
                                                            id="question1score"
                                                          >
                                                            <p></p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="clear"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {buttonCount == 8 && (
                                              <div class="questionanswers component section act-box even first odd">
                                                <div class="questionanswers-container">
                                                  <div class="quiz questionWithRadio component initialized questionview-2">
                                                    <div class="component-content">
                                                      <div class="quizQuestion">
                                                        <p class="quesLeftCaption">
                                                          Question 7
                                                        </p>
                                                        <p>
                                                          During the last 4
                                                          weeks, how many days
                                                          did your child wake up
                                                          during the night
                                                          because of asthma?
                                                        </p>
                                                        <p></p>
                                                        <div
                                                          class="imgContainer box-row"
                                                          id="question1"
                                                        >
                                                          {childQuesSeven.map(
                                                            (obj) => (
                                                              <div class="genericFormGroup box-class">
                                                                <span class="formControl-radio">
                                                                  <input
                                                                    type="radio"
                                                                    id="child-2-answer1"
                                                                    data-weightage="0"
                                                                    name="child-2"
                                                                  />
                                                                </span>

                                                                <label
                                                                  onClick={this.handleQuesSeven.bind(
                                                                    this,
                                                                    obj
                                                                  )}
                                                                  style={{
                                                                    backgroundColor:
                                                                      childSeventhId ==
                                                                      obj.title
                                                                        ? "#358dbe"
                                                                        : "",

                                                                    color:
                                                                      childSeventhId ==
                                                                      obj.title
                                                                        ? "#FFFFFF"
                                                                        : "",
                                                                  }}
                                                                  for="child-1-answer4"
                                                                >
                                                                  {obj.title}
                                                                </label>
                                                              </div>
                                                            )
                                                          )}

                                                          <div
                                                            class="quizScoreBox"
                                                            id="question1score"
                                                          >
                                                            <p></p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="clear"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                            {buttonCount === 5 ? (
                                              <div class="snippetReference component section default-style odd col-xs-12 ">
                                                <div class="inner">
                                                  <div class="component-content">
                                                    <a
                                                      id="01842128035"
                                                      aria-hidden="true"
                                                    ></a>
                                                    <div class="box component section content-container first odd last">
                                                      <div
                                                        class="component-content"
                                                        id="1872186977"
                                                      >
                                                        <div class="paragraphSystem content">
                                                          <a
                                                            id="01875191241"
                                                            aria-hidden="true"
                                                          ></a>
                                                          <div class="box component section quiz-part first odd last questionview-5">
                                                            <div
                                                              class="component-content"
                                                              id="1697569545"
                                                            >
                                                              <div class="paragraphSystem content">
                                                                <a
                                                                  id="01200668961"
                                                                  aria-hidden="true"
                                                                ></a>
                                                                <div class="richText component section quiz-part-header first odd">
                                                                  <div class="component-content">
                                                                    <div class="richText-content">
                                                                      <h2>
                                                                        PART 2
                                                                      </h2>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div class="richText component section quiz-part-desc even">
                                                                  <div class="component-content">
                                                                    <div class="richText-content">
                                                                      <h3>
                                                                        Adult
                                                                      </h3>
                                                                      <p>
                                                                        Please
                                                                        complete
                                                                        the
                                                                        following
                                                                        questions
                                                                        on your
                                                                        own.
                                                                      </p>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div class="image component section quiz-part-banner odd last">
                                                                  <div class="component-content left">
                                                                    <div class="analytics-image-tracking"></div>
                                                                    <img
                                                                      class="small-img01"
                                                                      src={
                                                                        adultChild
                                                                      }
                                                                      alt="Adult No Children"
                                                                      title="Adult No Children"
                                                                    />
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )}

                                            <div class="quizQuestionNav">
                                              <div
                                                class={
                                                  buttonActive === true ||
                                                  buttonCount === 5
                                                    ? "richText nav-arrow-right nav-button nav-button-arrow-right"
                                                    : "richText nav-arrow-right nav-button disabled nav-button-arrow-right"
                                                }
                                              >
                                                <div class="component-content">
                                                  <div
                                                    onClick={this.handleNextQues.bind(
                                                      this
                                                    )}
                                                    class="richText-content"
                                                  >
                                                    <p>
                                                      <a>
                                                        {" "}
                                                        {buttonCount === 8
                                                          ? "See Result"
                                                          : buttonCount === 5
                                                          ? "Take me to part 2"
                                                          : "Next question"}
                                                      </a>
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="richText previous-nav-link">
                                                <div
                                                  onClick={this.backTo.bind(
                                                    this
                                                  )}
                                                  class="component-content"
                                                >
                                                  <div class="richText-content">
                                                    <p>
                                                      <a>
                                                        {" "}
                                                        {buttonCount > 1
                                                          ? "Previous question"
                                                          : "Back to Introduction"}{" "}
                                                      </a>
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                              {buttonCount > 7 ? (
                                                <p class="answerAllInfo">
                                                  Please answer all the
                                                  questions
                                                </p>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="quiz quizProgressBar initialized">
                                      <div class="quizProgressBarQuestions"></div>
                                      <div class="quizProgressBarDots">
                                        <div class="progressDotActive"></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="quiz quizResultContainer initialized"
                                    style={{ display: "none" }}
                                  >
                                    <div class="quizScoreBox totalScoreBox component-content">
                                      <p></p>
                                    </div>
                                    <div class="clear"></div>
                                    <div class="component-content">
                                      <button
                                        class="button generateScore"
                                        type="button"
                                      >
                                        See result
                                      </button>
                                    </div>
                                  </div>
                                  <div class="quiz initialized">
                                    <div class="quizOverlayContainer">
                                      <div class="errorQuizOverlay overlay-bg">
                                        <div class="overlay-content">
                                          <div
                                            class="overlay-close"
                                            tabindex="0"
                                            style={{ display: "block" }}
                                          ></div>
                                          <h4>Ups... Something went wrong!</h4>
                                          <p class="errorQuestion">
                                            Please check Question::<span></span>
                                          </p>
                                        </div>
                                      </div>
                                      <div class="resultQuizOverlay overlay-bg">
                                        <div class="overlay-content">
                                          <div
                                            class="overlay-close"
                                            tabindex="0"
                                            style={{ display: "block" }}
                                          ></div>
                                          <div class="overlayContainerDiv"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    id="questionContainer"
                                    class="quizHideBox"
                                  >
                                    <div class="quizRecommendationContainer">
                                      <span
                                        class="quizRecommendation"
                                        data-min-range="0"
                                        data-max-range="9999"
                                        data-url="/content/cf-pharma/asthma-control-test/en_GB/home/quiz/results.html"
                                      ></span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="richText component section quiz-button-text even last col-xs-12">
                                <div class="component-content">
                                  <div class="richText-content">
                                    <p>
                                      Next question//Please answer all the
                                      questions//Previous question//
                                      <a href="../introduction.html">
                                        Back to Introduction
                                      </a>
                                      //Back to previous page//See result//Take
                                      me to part 2
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="footer" class="col-xs-12">
              <div class="row">
                <div class="layout-outer">
                  <div class="layout-inner">
                    <div class="col-xs-12 col-md-12 default-style">
                      <div
                        class="box component section default-style first odd last col-xs-12"
                        style={{ padding: 0 }}
                      >
                        <div class="component-content" id="0808245730">
                          <div class="paragraphSystem content">
                            <div class="snippetReference component section footer-section footer-section-legals even last col-xs-12 reference-footer-legals-2">
                              <div class="inner">
                                <div class="component-content">
                                  <div class="richText component section footer-paragraphs odd">
                                    <div class="component-content">
                                      <div class="richText-content">
                                        <p>
                                          © 2023 My HealthCK. All rights
                                          reserved.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="Footer paragraphSystem"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="hide-bg-image-ph"
            style={{
              position: "fixed",
              bottom: 50,
              right: 10,
              opacity: 0.6,
              textAlign: "right",
            }}
          >
            <img src={childBg} />
          </div>
        </div>
      </body>
    );
  }
}

export default index;
