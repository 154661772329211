import React, { Component } from "react";
import "./../style.scss";
import first from "../../assets/home/first.png";
import second from "../../assets/home/second.png";
import fourth from "../../assets/home/fourth.png";
import fifth from "../../assets/home/fifth.png";
import sixth from "../../assets/home/sixth.png";
import health from "../../assets/home/healthck-cfb340e96980107935c82dd7fb92b2d6.png";
import third from "../../assets/home/third.png";
import "../../assets/css/bootstrap4.css";

class index extends React.Component {
  state = {};

  handlWelcome() {
    const { history } = this.props;
    history.push("/asthmaCK");
  }

  render() {
    const { history } = this.props;
    const { fields, errors } = this.state;

    return (
      <div>
        <section>
          <div class="con-top">
            <div class="row bg-dark w-100">
            

              <div class="col-md-6 p-0">
                <div class="content">
                  <a target="_blank" href="/asthmaCK">
                    <div class="content-4"></div>
                    <div class="forth-bg first-immg-check">
                      <img src={fourth} alt="" />
                    </div>
                    <div class="content-details fadeIn-top px-5 text-left">
                      <h4 class="text-white ">
                        Asthma CK - How well controlled is your asthma ?
                      </h4>
                      <button class="btn bg-white border textreddark btn-hov-four radius-20 px-4 mt-5">
                        Launch
                      </button>
                    </div>
                  </a>
                </div>
              </div>

              <div class="col-md-6 p-0">
                <div class="content">
                  <a target="_blank" href="/alcoholCK">
                    <div class="content-5"></div>
                    <div class="fifth-bg first-immg-check">
                      <img src={fifth} alt="" />
                    </div>
                    <div class="content-details fadeIn-top text-left">
                      <h4 class="text-white text-left">
                        Alcohol CK - Is my alcohol intake healthy ?
                      </h4>
                      <button class="btn bg-white border textlightred btn-hov-five radius-20 px-4 mt-5">
                        Launch
                      </button>
                    </div>
                  </a>
                </div>
              </div>
              <div class="col-md-6 p-0 " height="">
                <div class="content">
                  <a target="_blank" href="/sleepCK">
                    <div class="content-6"></div>
                    <div class="sixth-bg first-immg-check">
                      <img src={sixth} alt="" />
                    </div>
                    <div class="content-details fadeIn-top text-left">
                      <h4 class="text-white text-left">
                        Sleep CK - Am I at risk of sleep apnoea ?
                      </h4>
                      <button class="btn bg-white border light-greentext btn-hov-six radius-20 px-4 mt-5">
                        Launch
                      </button>
                    </div>
                  </a>
                </div>
              </div>
              <div class="col-md-6 p-0 " height="">
                <div class="content">
                  <a target="_blank" href="/sleepCK">
                    <div class="content-6"></div>
                    <div class="sixth-bg first-immg-check">
                    </div>
                    <div class="content-details fadeIn-top text-left">
                      <h4 class="text-white text-left">
                        coming soon
                      </h4>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="center-part-logo position-absolute w-100 ">
              <div class="bg-white pb-2 text-img-cent radius-20">
                <div class="img-center-log text-center py-3">
                  <img src={health} alt="" />
                </div>
                <h4 class=" text-center mb-3">
                  {" "}
                  Convenient,private and online health screening.
                </h4>
                <p class="text-grey-color text-center text-grey mb-3">
                  {" "}
                  Anywhere,Anytime,get checked
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default index;
