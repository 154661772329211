import React, { Component } from "react";
import "./../style.scss";
import logo from "../../assets/Welcome/logo.png";
import "../../assets/css/bootstrap.css";
import Cookies from "universal-cookie";
import { Slider, Box } from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";
import { withStyles, styled } from "@material-ui/core/styles";
import { connect } from "react-redux";

const cookies = new Cookies();

const styles = {};

const PrettoSlider = styled(Slider)({
  color: "#52af77",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#52af77",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 15,
    label: "15",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 25,
    label: "25",
  },
];

function valuetext(value) {
  return `${value}`;
}

class index extends React.Component {
  state = {
    windorWidth: window.innerWidth,
  };

  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleHome() {
    const { history } = this.props;
    history.push("/home");
  }

  render() {
    const { history, width } = this.props;

    console.log("ye rhiiiii ", JSON.stringify(width));
    const { fields, errors, windowWidth } = this.state;

    var newArr = [];

    let adultQuiz = cookies.get("adultQuiz");
    let childQuizz = cookies.get("childQuizz");
    let selectedTest = cookies.get("selectedTest");

    if (adultQuiz) {
      for (var i = 0; i < adultQuiz.length; i++) {
        newArr = newArr.concat(adultQuiz[i]);
      }
    }

    let totalChild = [];
    let totalAdults = [];
    let newTotalChild = "";
    let newTotalAdults = "";

    let one = "";
    let two = "";
    let three = "";
    let four = "";
    let five = "";
    let six = "";
    let seven = "";

    if (selectedTest == "child") {
      childQuizz.map((object) => totalChild.push(object.points));

      one = totalChild[0];
      two = totalChild[1];
      three = totalChild[2];
      four = totalChild[3];
      five = totalChild[4];
      six = totalChild[5];
      seven = totalChild[6];

      newTotalChild =
        parseInt(one) +
        parseInt(two) +
        parseInt(three) +
        parseInt(four) +
        parseInt(five) +
        parseInt(six) +
        parseInt(seven);
    }

    if (selectedTest == "adult") {
      newArr.map((object) => totalAdults.push(object.points));

      one = totalAdults[0];
      two = totalAdults[1];
      three = totalAdults[2];
      four = totalAdults[3];
      five = totalAdults[4];

      newTotalAdults =
        parseInt(one) +
        parseInt(two) +
        parseInt(three) +
        parseInt(four) +
        parseInt(five);
    }

    let newValue = "";

    if (newTotalAdults) {
      newValue = newTotalAdults;
    }
    if (newTotalChild) {
      newValue = newTotalChild;
    }

    return (
      <div>
        <body class="page-quiz page-results  layout-bootstrap-layout-12 template-branded-with-claims grid-bootstrap header-height-3 initialized">
          <div>
            <div class="row">
              <div id="header" class="col-xs-12">
                <div class="row">
                  <div class="layout-outer">
                    <div class="layout-inner">
                      <div class="col-xs-12 col-md-12 default-style">
                        <a
                          id="960044208"
                          style={{ visibility: "hidden" }}
                          aria-hidden="true"
                        ></a>
                        <div class="box component section content-container header-container height-variant-1 first odd last col-xs-12 col-xs-offset-0 col-md-12 col-md-offset-0">
                          <div class="component-content" id="01995300884">
                            <div class="paragraphSystem content">
                              <a
                                id="01700272810"
                                style={{ visibility: "hidden" }}
                                aria-hidden="true"
                              ></a>
                              <div class="snippetReference component section default-style first odd col-xs-6 reference-brand-area">
                                <div class="inner">
                                  <div class="component-content">
                                    <a
                                      id="0597899827"
                                      style={{ visibility: "hidden" }}
                                      aria-hidden="true"
                                    ></a>
                                    <div class="richTextEnhanced richText section richTextHyperlinkedImage default-style first odd last component">
                                      <div class="component-content">
                                        <a href="/home">
                                          {" "}
                                          <img
                                            src={logo}
                                            width="180"
                                            class="richText-image pull-left"
                                            alt=""
                                          />
                                        </a>
                                        <div class="richText-content">
                                          <ph></ph>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="Header paragraphSystem"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="content" class="col-xs-12">
                <div class="row">
                  <div class="layout-outer">
                    <div class="layout-inner">
                      <div class="col-xs-12 col-md-12 default-style">
                        <div class="Content paragraphSystem">
                          <a
                            id="675355002"
                            style={{ visibility: "hidden" }}
                            aria-hidden="true"
                          ></a>
                          <div class="box component section header-overlay first odd col-xs-12">
                            <div class="component-content" id="1171928872">
                              <div class="paragraphSystem content">
                                <a
                                  id="0177012590"
                                  style={{ visibility: "hidden" }}
                                  aria-hidden="true"
                                ></a>
                                <div
                                  class="richText component section child-content main-header move-top first odd col-xs-12"
                                  style={{ display: "none" }}
                                >
                                  <div class="component-content">
                                    <div class="richText-content">
                                      <h1>
                                        <span class="no-wrap">
                                          Your Child’s Asthma
                                        </span>{" "}
                                        <span class="no-wrap">
                                          Control Test Score
                                        </span>
                                      </h1>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="richText component section adult-content main-header move-top even last col-xs-12"
                                  style={{ display: "block" }}
                                >
                                  <div class="component-content">
                                    <div class="richText-content">
                                      <h1>
                                        <span class="no-wrap">
                                          Your Health Check
                                        </span>{" "}
                                        <span class="no-wrap">
                                          Control Test Score
                                        </span>
                                      </h1>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <Box sx={{ width:'50%', marginLeft:100, marginRight:100}}>
                                                        <Slider
                                                        disabled
                                                       
                                                            aria-label="Always visible"
                                                            defaultValue={selectedTest == "adult" ? newTotalAdults : newTotalChild}
                                                            getAriaValueText={valuetext}
                                                            marks={marks}
                                                            valueLabelDisplay="on"
                                                        />
                                                    </Box> */}
                          <div class="box component section content-container even col-xs-12 col-xs-offset-0 col-md-12 col-md-offset-0">
                            <div class="component-content" id="141654997">
                              <div class="paragraphSystem content">
                                <a
                                  id="01866119105"
                                  style={{ visibility: "hidden" }}
                                  aria-hidden="true"
                                ></a>
                                <div class="parametrizedhtml component section default-style first odd col-xs-12 reference-quiz-result-bar initialized">
                                  <div class="component-content">
                                    <div class="quiz-result-bar-container">
                                      {newValue == "0" && (
                                        <div
                                          id="myDIV"
                                          class="quiz-result-bar-score firts-0"
                                        >
                                          <p>
                                            <span class="score">
                                              {selectedTest == "child"
                                                ? newTotalChild
                                                : newTotalAdults}
                                            </span>{" "}
                                            your score
                                          </p>
                                        </div>
                                      )}

                                      {/* end */}
                                      {newValue == "1" && (
                                        <div
                                          id="myDIV"
                                          class="quiz-result-bar-score firts-1"
                                        >
                                          <p>
                                            <span class="score">
                                              {selectedTest == "child"
                                                ? newTotalChild
                                                : newTotalAdults}
                                            </span>{" "}
                                            your score
                                          </p>
                                        </div>
                                      )}
                                      {/* end */}
                                      {newValue == "2" && (
                                        <div
                                          id="myDIV"
                                          class="quiz-result-bar-score firts-2"
                                        >
                                          <p>
                                            <span class="score">
                                              {selectedTest == "child"
                                                ? newTotalChild
                                                : newTotalAdults}
                                            </span>{" "}
                                            your score
                                          </p>
                                        </div>
                                      )}
                                      {/* end */}
                                      {newValue == "3" && (
                                        <div
                                          id="myDIV"
                                          class="quiz-result-bar-score firts-3"
                                        >
                                          <p>
                                            <span class="score">
                                              {selectedTest == "child"
                                                ? newTotalChild
                                                : newTotalAdults}
                                            </span>{" "}
                                            your score
                                          </p>
                                        </div>
                                      )}
                                      {/* end */}
                                      {newValue == "4" && (
                                        <div
                                          id="myDIV"
                                          class="quiz-result-bar-score firts-4"
                                        >
                                          <p>
                                            <span class="score">
                                              {selectedTest == "child"
                                                ? newTotalChild
                                                : newTotalAdults}
                                            </span>{" "}
                                            your score
                                          </p>
                                        </div>
                                      )}
                                      {/* end */}
                                      {newValue == "5" && (
                                        <div
                                          id="myDIV"
                                          class="quiz-result-bar-score firts-5"
                                        >
                                          <p>
                                            <span class="score">
                                              {selectedTest == "child"
                                                ? newTotalChild
                                                : newTotalAdults}
                                            </span>{" "}
                                            your score
                                          </p>
                                        </div>
                                      )}
                                      {/* end */}
                                      {newValue == "6" && (
                                        <div
                                          id="myDIV"
                                          class="quiz-result-bar-score firts-6"
                                        >
                                          <p>
                                            <span class="score">
                                              {selectedTest == "child"
                                                ? newTotalChild
                                                : newTotalAdults}
                                            </span>{" "}
                                            your score
                                          </p>
                                        </div>
                                      )}
                                      {/* end */}
                                      {newValue == "7" && (
                                        <div
                                          id="myDIV"
                                          class="quiz-result-bar-score firts-7"
                                        >
                                          <p>
                                            <span class="score">
                                              {selectedTest == "child"
                                                ? newTotalChild
                                                : newTotalAdults}
                                            </span>{" "}
                                            your score
                                          </p>
                                        </div>
                                      )}
                                      {/* end */}
                                      {newValue == "8" && (
                                        <div
                                          id="myDIV"
                                          class="quiz-result-bar-score firts-8"
                                        >
                                          <p>
                                            <span class="score">
                                              {selectedTest == "child"
                                                ? newTotalChild
                                                : newTotalAdults}
                                            </span>{" "}
                                            your score
                                          </p>
                                        </div>
                                      )}

                                      {/* end */}
                                      {newValue == "9" && (
                                        <div
                                          id="myDIV"
                                          class="quiz-result-bar-score firts-9"
                                        >
                                          <p>
                                            <span class="score">
                                              {selectedTest == "child"
                                                ? newTotalChild
                                                : newTotalAdults}
                                            </span>{" "}
                                            your score
                                          </p>
                                        </div>
                                      )}
                                      {/* end */}
                                      {newValue == "10" && (
                                        <div
                                          id="myDIV"
                                          class="quiz-result-bar-score firts-10"
                                        >
                                          <p>
                                            <span class="score">
                                              {selectedTest == "child"
                                                ? newTotalChild
                                                : newTotalAdults}
                                            </span>{" "}
                                            your score
                                          </p>
                                        </div>
                                      )}
                                      {/* end */}
                                      {newValue == "11" && (
                                        <div
                                          id="myDIV"
                                          class="quiz-result-bar-score firts-11"
                                        >
                                          <p>
                                            <span class="score">
                                              {selectedTest == "child"
                                                ? newTotalChild
                                                : newTotalAdults}
                                            </span>{" "}
                                            your score
                                          </p>
                                        </div>
                                      )}
                                      {/* end */}
                                      {newValue == "12" && (
                                        <div
                                          id="myDIV"
                                          class="quiz-result-bar-score firts-12"
                                        >
                                          <p>
                                            <span class="score">
                                              {selectedTest == "child"
                                                ? newTotalChild
                                                : newTotalAdults}
                                            </span>{" "}
                                            your score
                                          </p>
                                        </div>
                                      )}
                                      {/* end */}
                                      {newValue == "13" && (
                                        <div
                                          id="myDIV"
                                          class="quiz-result-bar-score firts-13"
                                        >
                                          <p>
                                            <span class="score">
                                              {selectedTest == "child"
                                                ? newTotalChild
                                                : newTotalAdults}
                                            </span>{" "}
                                            your score
                                          </p>
                                        </div>
                                      )}
                                      {/* end */}
                                      {newValue == "14" && (
                                        <div
                                          id="myDIV"
                                          class="quiz-result-bar-score firts-14"
                                        >
                                          <p>
                                            <span class="score">
                                              {selectedTest == "child"
                                                ? newTotalChild
                                                : newTotalAdults}
                                            </span>{" "}
                                            your score
                                          </p>
                                        </div>
                                      )}
                                      {/* end */}
                                      {newValue == "15" && (
                                        <div
                                          id="myDIV"
                                          class="quiz-result-bar-score firts-15"
                                        >
                                          <p>
                                            <span class="score">
                                              {selectedTest == "child"
                                                ? newTotalChild
                                                : newTotalAdults}
                                            </span>{" "}
                                            your score
                                          </p>
                                        </div>
                                      )}
                                      {/* end */}
                                      {newValue == "16" && (
                                        <div
                                          id="myDIV"
                                          class="quiz-result-bar-score firts-16"
                                        >
                                          <p>
                                            <span class="score">
                                              {selectedTest == "child"
                                                ? newTotalChild
                                                : newTotalAdults}
                                            </span>{" "}
                                            your score
                                          </p>
                                        </div>
                                      )}
                                      {/* end */}
                                      {newValue == "17" && (
                                        <div
                                          id="myDIV"
                                          class="quiz-result-bar-score firts-17"
                                        >
                                          <p>
                                            <span class="score">
                                              {selectedTest == "child"
                                                ? newTotalChild
                                                : newTotalAdults}
                                            </span>{" "}
                                            your score
                                          </p>
                                        </div>
                                      )}
                                      {/* end */}
                                      {newValue == "18" && (
                                        <div
                                          id="myDIV"
                                          class="quiz-result-bar-score firts-18"
                                        >
                                          <p>
                                            <span class="score">
                                              {selectedTest == "child"
                                                ? newTotalChild
                                                : newTotalAdults}
                                            </span>{" "}
                                            your score
                                          </p>
                                        </div>
                                      )}
                                      {/* end */}
                                      {newValue == "19" && (
                                        <div
                                          id="myDIV"
                                          class="quiz-result-bar-score firts-19"
                                        >
                                          <p>
                                            <span class="score">
                                              {selectedTest == "child"
                                                ? newTotalChild
                                                : newTotalAdults}
                                            </span>{" "}
                                            your score
                                          </p>
                                        </div>
                                      )}
                                      {/* end */}
                                      {newValue == "20" && (
                                        <div
                                          id="myDIV"
                                          class="quiz-result-bar-score firts-20"
                                        >
                                          <p>
                                            <span class="score">
                                              {selectedTest == "child"
                                                ? newTotalChild
                                                : newTotalAdults}
                                            </span>{" "}
                                            your score
                                          </p>
                                        </div>
                                      )}
                                      {/* end */}
                                      {newValue == "21" && (
                                        <div
                                          id="myDIV"
                                          class="quiz-result-bar-score firts-21"
                                        >
                                          <p>
                                            <span class="score">
                                              {selectedTest == "child"
                                                ? newTotalChild
                                                : newTotalAdults}
                                            </span>{" "}
                                            your score
                                          </p>
                                        </div>
                                      )}
                                      {/* end */}
                                      {newValue == "22" && (
                                        <div
                                          id="myDIV"
                                          class="quiz-result-bar-score firts-22"
                                        >
                                          <p>
                                            <span class="score">
                                              {selectedTest == "child"
                                                ? newTotalChild
                                                : newTotalAdults}
                                            </span>{" "}
                                            your score
                                          </p>
                                        </div>
                                      )}
                                      {/* end */}
                                      {newValue == "23" && (
                                        <div
                                          id="myDIV"
                                          class="quiz-result-bar-score firts-23"
                                        >
                                          <p>
                                            <span class="score">
                                              {selectedTest == "child"
                                                ? newTotalChild
                                                : newTotalAdults}
                                            </span>{" "}
                                            your score
                                          </p>
                                        </div>
                                      )}
                                      {/* end */}
                                      {newValue == "24" && (
                                        <div
                                          id="myDIV"
                                          class="quiz-result-bar-score firts-24"
                                        >
                                          <p>
                                            <span class="score">
                                              {selectedTest == "child"
                                                ? newTotalChild
                                                : newTotalAdults}
                                            </span>{" "}
                                            your score
                                          </p>
                                        </div>
                                      )}
                                      {/* end */}
                                      {newValue == "25" && (
                                        <div
                                          id="myDIV"
                                          class="quiz-result-bar-score firts-25"
                                        >
                                          <p>
                                            <span class="score">
                                              {selectedTest == "child"
                                                ? newTotalChild
                                                : newTotalAdults}
                                            </span>{" "}
                                            your score
                                          </p>
                                        </div>
                                      )}
                                      {/* end */}

                                      <div class="quiz-result-bar-wrapper">
                                        <div class="quiz-result-bar-red">
                                          <div class="quiz-result-bar-color"></div>
                                          <p class="quiz-result-bar-text desktop-only">
                                            Very poorly controlled Health Check
                                          </p>
                                        </div>
                                        <div class="quiz-result-bar-yellow">
                                          <div class="quiz-result-bar-color"></div>
                                          <p class="quiz-result-bar-text desktop-only">
                                            Poorly controlled Health Check
                                          </p>
                                        </div>
                                        <div class="quiz-result-bar-green">
                                          <div class="quiz-result-bar-color"></div>
                                          <p class="quiz-result-bar-text desktop-only">
                                            Well-controlled Health Check
                                          </p>
                                        </div>
                                      </div>
                                      <div class="quiz-result-bar-mobile mobile-only">
                                        <p class="quiz-result-bar-text custom-bar-text">
                                          Very poorly controlled Health Check
                                        </p>
                                        <p class="quiz-result-bar-text custom-bar-text">
                                          Poorly controlled Health Check
                                        </p>
                                        <p class="quiz-result-bar-text custom-bar-text">
                                          Well-controlled Health Check
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="richText component section child-content child-content-u20 even col-xs-12"
                                  style={{ display: "none" }}
                                >
                                  <div class="component-content">
                                    <div class="richText-content">
                                      <p style={{ textAlign: "center" }}>
                                        <a
                                          href="#"
                                          class="save-results initialized"
                                        >
                                          Save my result
                                        </a>
                                      </p>
                                      <h2>
                                        Your child´s asthma symptoms may not be
                                        as well controlled as they could be.
                                      </h2>
                                      <p>
                                        NOTE: If your child’s score is 12 or
                                        less, his or her asthma may be very
                                        poorly controlled. Regardless of your
                                        child's score, continue to talk to your
                                        healthcare provider.
                                        <br /> There may be more you and your
                                        child's healthcare provider could do to
                                        help control your child's asthma
                                        symptoms.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="richText component section child-content child-content-a20 odd col-xs-12"
                                  style={{ display: "none" }}
                                >
                                  <div class="component-content">
                                    <div class="richText-content">
                                      <p style={{ textAlign: "center" }}>
                                        <a
                                          href="#"
                                          class="save-results initialized"
                                        >
                                          Save my result
                                        </a>
                                      </p>
                                      <h2>
                                        Your child´s asthma symptoms may be well
                                        controlled
                                      </h2>
                                      <p>
                                        Regardless of your child's score,
                                        continue to talk to your healthcare
                                        provider.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="richText component section adult-content adult-content-u20 even col-xs-12"
                                  style={{ display: "block" }}
                                >
                                  <div class="component-content">
                                    <div class="richText-content">
                                      <p style={{ textAlign: "center" }}>
                                        <a
                                          href="#"
                                          class="save-results initialized"
                                        >
                                          Save my result
                                        </a>
                                      </p>
                                      <h2>
                                        Your asthma symptoms may not be as well
                                        controlled as they could be
                                      </h2>
                                      <p>
                                        If your score is 15 or less, your asthma
                                        may be very poorly controlled.
                                        Regardless of your score, continue to
                                        talk to your healthcare provider. There
                                        may be more you and your healthcare
                                        provider could do to help control your
                                        asthma symptoms.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="richText component section adult-content adult-content-a20 odd col-xs-12"
                                  style={{ display: "none" }}
                                >
                                  <div class="component-content">
                                    <div class="richText-content">
                                      <p style={{ textAlign: "center" }}>
                                        <a
                                          href="#"
                                          class="save-results initialized"
                                        >
                                          Save my result
                                        </a>
                                      </p>
                                      <h2>
                                        Your Asthma Symptoms may be well
                                        controlled
                                      </h2>
                                      <p>
                                        Your asthma symptoms appear to be
                                        well-controlled. Even so, asthma control
                                        can change over time so it´s important
                                        to retest yourself regularly. Regardless
                                        of your score, continue to talk to your
                                        healthcare provider.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                {/* dikkt */}

                                <div class="box component section border-grey even last col-xs-12 col-md-8 col-md-offset-2">
                                  <div
                                    class="component-content"
                                    id="01603277829"
                                  >
                                    <div class="paragraphSystem content">
                                      <a
                                        id="1031549541"
                                        style={{ visibility: "hidden" }}
                                        aria-hidden="true"
                                      ></a>
                                      <div
                                        class="richText component section child-content month-from-now first odd col-xs-12 initialized"
                                        style={{ display: "none" }}
                                      >
                                        <div class="component-content">
                                          <div class="richText-content">
                                            <p style={{ textAlign: "center" }}>
                                              <strong>
                                                We suggest your child take the
                                                test{" "}
                                                <span class="no-wrap">
                                                  again on 16/12/2021
                                                </span>
                                              </strong>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="richText component section adult-content month-from-now even col-xs-12 initialized"
                                        style={{ display: "block" }}
                                      >
                                        <div class="component-content">
                                          <div class="richText-content">
                                            <p style={{ textAlign: "center" }}>
                                              <strong>
                                                We suggest you take the test{" "}
                                                <span class="">
                                                  again on 16/12/2021
                                                </span>
                                              </strong>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="parametrizedhtml component section default-style odd last col-xs-12 reference-appointment-scheduler">
                                        <div class="component-content">
                                          <div>
                                            <a
                                              class="tooltip label icon-calendar add-to-calendar initialized"
                                              href="#"
                                              id="248950"
                                            >
                                              Add to my calendar
                                              <div class="tooltip-msg">
                                                <a
                                                  class="icon-google"
                                                  target="_blank"
                                                  href="https://www.google.com/calendar/render?action=TEMPLATE&amp;text=www.AsthmaControlTest.com&amp;dates=20211215T195112Z/20211215T200612Z&amp;details=Retaking%20the%20test%20regularly%20will%20help%20you%20understand%20how%20your%20asthma%20is%20changing%20over%20time.%20We%20recommend%20that%20you%20retake%20the%20test%20monthly.%20That%20way,%20you%20and%20your%20doctor%20can%20work%20together%20to%20keep%20your%20asthma%20under%20control.&amp;location=www.AsthmaControlTest.com&amp;sprop=&amp;sprop=name:"
                                                >
                                                  Google
                                                </a>
                                                <a
                                                  class="icon-yahoo"
                                                  target="_blank"
                                                  href="http://calendar.yahoo.com/?v=60&amp;view=d&amp;type=20&amp;title=www.AsthmaControlTest.com&amp;st=20211216T012112Z&amp;dur=0015&amp;desc=Retaking%20the%20test%20regularly%20will%20help%20you%20understand%20how%20your%20asthma%20is%20changing%20over%20time.%20We%20recommend%20that%20you%20retake%20the%20test%20monthly.%20That%20way,%20you%20and%20your%20doctor%20can%20work%20together%20to%20keep%20your%20asthma%20under%20control.&amp;in_loc=www.AsthmaControlTest.com"
                                                >
                                                  Yahoo!
                                                </a>
                                                <a
                                                  class="icon-ical"
                                                  target="_blank"
                                                  href="data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0AVERSION:2.0%0ABEGIN:VEVENT%0AURL:https://www.asthmacontroltest.com/content/cf-pharma/asthma-control-test/en_GB/home/quiz/results.html%0ADTSTART:20211215T195112Z%0ADTEND:20211215T200612Z%0ASUMMARY:www.AsthmaControlTest.com%0ADESCRIPTION:Retaking%20the%20test%20regularly%20will%20help%20you%20understand%20how%20your%20asthma%20is%20changing%20over%20time.%20We%20recommend%20that%20you%20retake%20the%20test%20monthly.%20That%20way,%20you%20and%20your%20doctor%20can%20work%20together%20to%20keep%20your%20asthma%20under%20control.%0ALOCATION:www.AsthmaControlTest.com%0AEND:VEVENT%0AEND:VCALENDAR"
                                                >
                                                  iCal
                                                </a>
                                                <a
                                                  class="icon-outlook"
                                                  target="_blank"
                                                  href="data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0AVERSION:2.0%0ABEGIN:VEVENT%0AURL:https://www.asthmacontroltest.com/content/cf-pharma/asthma-control-test/en_GB/home/quiz/results.html%0ADTSTART:20211215T195112Z%0ADTEND:20211215T200612Z%0ASUMMARY:www.AsthmaControlTest.com%0ADESCRIPTION:Retaking%20the%20test%20regularly%20will%20help%20you%20understand%20how%20your%20asthma%20is%20changing%20over%20time.%20We%20recommend%20that%20you%20retake%20the%20test%20monthly.%20That%20way,%20you%20and%20your%20doctor%20can%20work%20together%20to%20keep%20your%20asthma%20under%20control.%0ALOCATION:www.AsthmaControlTest.com%0AEND:VEVENT%0AEND:VCALENDAR"
                                                >
                                                  Outlook
                                                </a>
                                              </div>
                                            </a>
                                            <a
                                              href="#"
                                              class="tooltip tooltip-child initialized"
                                              style={{ display: "none" }}
                                            >
                                              Why should I take the test again?
                                              <span class="tooltip-msg">
                                                Retaking the test regularly will
                                                help you understand how your
                                                child’s asthma is changing over
                                                time. We suggest that the test
                                                is taken monthly. That way, you
                                                and your doctor can work
                                                together to keep your child´s
                                                asthma under control.
                                              </span>
                                            </a>
                                            <a
                                              href="#"
                                              class="tooltip tooltip-adult initialized"
                                              style={{
                                                display: "inline-block",
                                              }}
                                            >
                                              Why should I take the test again?
                                              <span class="tooltip-msg">
                                                Retaking the test regularly will
                                                help you understand how your
                                                asthma is changing over time. We
                                                recommend that you retake the
                                                test monthly. That way, you and
                                                your doctor can work together to
                                                keep your asthma under control.
                                              </span>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="box component section background-secondary content-container answer-box odd col-xs-12">
                            <div class="component-content" id="02032490570">
                              <div class="paragraphSystem content">
                                <a
                                  id="1694040864"
                                  style={{ visibility: "hidden" }}
                                  aria-hidden="true"
                                ></a>
                                <div class="richText component section default-style first odd col-xs-12">
                                  <div class="component-content">
                                    <div class="richText-content">
                                      <h2>See your answers below</h2>
                                    </div>
                                  </div>
                                </div>
                                <div class="box component section quiz-results even last col-xs-12">
                                  <div
                                    class="component-content"
                                    id="1821986396"
                                  >
                                    <div class="paragraphSystem content">
                                      <a
                                        id="823239622"
                                        style={{ visibility: "hidden" }}
                                        aria-hidden="true"
                                      ></a>
                                      <div class="parametrizedhtml component section default-style first odd last col-xs-12 reference-quiz-result-answers initialized">
                                        <div class="component-content">
                                          <div class="answers-container">
                                            {selectedTest == "adult"
                                              ? newArr.map((obj, index) => (
                                                  <div class="answer">
                                                    <p>
                                                      <b>
                                                        {index +
                                                          1 +
                                                          ".  " +
                                                          obj.question}
                                                      </b>
                                                    </p>
                                                    <div class="divider"></div>
                                                    <p>{obj.title}</p>
                                                    <div class="score">
                                                      <div class="score-content">
                                                        <p>
                                                          <span class="points">
                                                            {obj.points}
                                                          </span>
                                                          /5
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ))
                                              : childQuizz.map((obj, index) => (
                                                  <div class="answer">
                                                    <p>
                                                      <b>
                                                        {index +
                                                          1 +
                                                          ".  " +
                                                          obj.question}
                                                      </b>
                                                    </p>
                                                    <div class="divider"></div>
                                                    <p>{obj.title}</p>
                                                    <div class="score">
                                                      <div class="score-content">
                                                        <p>
                                                          <span class="points">
                                                            {obj.points}
                                                          </span>
                                                          /5
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ))}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="box component section content-container even col-xs-12">
                            <div class="component-content" id="1768744210">
                              <div class="paragraphSystem content">
                                <a
                                  id="748037244"
                                  style={{ visibility: "hidden" }}
                                  aria-hidden="true"
                                ></a>
                                <div class="richText component section save-your-results first odd col-xs-12">
                                  <div class="component-content">
                                    <div class="richText-content">
                                      {/* <h2 style={{ textAlign: 'center' }} >Save your test results</h2> */}
                                      <p style={{ textAlign: "center" }}>
                                        <a
                                          href="#"
                                          class="call-to-action icon-email initialized tooltip"
                                        >
                                          Email result
                                          <span class="tooltip-msg">
                                            <label>
                                              Please enteryour email
                                            </label>
                                            <input />
                                            <button style={{ display: "none" }}>
                                              SEND
                                            </button>
                                            <p style={{ display: "none" }}>
                                              Email has been sent
                                            </p>
                                          </span>
                                        </a>
                                        <a
                                          onClick={this.handleHome.bind(this)}
                                          class="call-to-action"
                                        >
                                          Home
                                        </a>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="divider component section default-style even"
                                  style={{
                                    textAlign: "center",
                                    marginTop: "10",
                                    float: "left",
                                  }}
                                >
                                  <div class="component-content"></div>
                                </div>
                                <div
                                  class="richText component section adult-content odd col-xs-12"
                                  style={{ display: "block" }}
                                >
                                  <div class="component-content">
                                    <div class="richText-content">
                                      <h2 style={{ textAlign: "center" }}>
                                        We suggest you take the test regularly
                                      </h2>
                                      <h6 style={{ textAlign: "center" }}>
                                        Occupational Health CK
                                      </h6>
                                      <h6 style={{ textAlign: "center" }}>
                                        8/1 Merino Entrance Cockburn Central
                                        6164
                                      </h6>
                                      <h6 style={{ textAlign: "center" }}>
                                        Australia
                                      </h6>
                                      <h6 style={{ textAlign: "center" }}>
                                        +61 435432345
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="richText component section child-content even col-xs-12"
                                  style={{ display: "none" }}
                                >
                                  <div class="component-content">
                                    <div class="richText-content">
                                      <h2 style={{ textAlign: "center" }}>
                                        We encourage your child to take the test
                                        regularly
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                                <div class="parametrizedhtml component section default-style odd last col-xs-12 reference-appointment-scheduler">
                                  <div class="component-content">
                                    <div>
                                      <a
                                        class="tooltip label icon-calendar add-to-calendar initialized"
                                        href="#"
                                        id="376508"
                                      >
                                        Add to my calendar
                                        <div class="tooltip-msg">
                                          <a
                                            class="icon-google"
                                            target="_blank"
                                            href="https://www.google.com/calendar/render?action=TEMPLATE&amp;text=www.AsthmaControlTest.com&amp;dates=20211215T195112Z/20211215T200612Z&amp;details=Retaking%20the%20test%20regularly%20will%20help%20you%20understand%20how%20your%20asthma%20is%20changing%20over%20time.%20We%20recommend%20that%20you%20retake%20the%20test%20monthly.%20That%20way,%20you%20and%20your%20doctor%20can%20work%20together%20to%20keep%20your%20asthma%20under%20control.&amp;location=www.AsthmaControlTest.com&amp;sprop=&amp;sprop=name:"
                                          >
                                            Google
                                          </a>
                                          <a
                                            class="icon-yahoo"
                                            target="_blank"
                                            href="http://calendar.yahoo.com/?v=60&amp;view=d&amp;type=20&amp;title=www.AsthmaControlTest.com&amp;st=20211216T012112Z&amp;dur=0015&amp;desc=Retaking%20the%20test%20regularly%20will%20help%20you%20understand%20how%20your%20asthma%20is%20changing%20over%20time.%20We%20recommend%20that%20you%20retake%20the%20test%20monthly.%20That%20way,%20you%20and%20your%20doctor%20can%20work%20together%20to%20keep%20your%20asthma%20under%20control.&amp;in_loc=www.AsthmaControlTest.com"
                                          >
                                            Yahoo!
                                          </a>
                                          <a
                                            class="icon-ical"
                                            target="_blank"
                                            href="data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0AVERSION:2.0%0ABEGIN:VEVENT%0AURL:https://www.asthmacontroltest.com/content/cf-pharma/asthma-control-test/en_GB/home/quiz/results.html%0ADTSTART:20211215T195112Z%0ADTEND:20211215T200612Z%0ASUMMARY:www.AsthmaControlTest.com%0ADESCRIPTION:Retaking%20the%20test%20regularly%20will%20help%20you%20understand%20how%20your%20asthma%20is%20changing%20over%20time.%20We%20recommend%20that%20you%20retake%20the%20test%20monthly.%20That%20way,%20you%20and%20your%20doctor%20can%20work%20together%20to%20keep%20your%20asthma%20under%20control.%0ALOCATION:www.AsthmaControlTest.com%0AEND:VEVENT%0AEND:VCALENDAR"
                                          >
                                            iCal
                                          </a>
                                          <a
                                            class="icon-outlook"
                                            target="_blank"
                                            href="data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0AVERSION:2.0%0ABEGIN:VEVENT%0AURL:https://www.asthmacontroltest.com/content/cf-pharma/asthma-control-test/en_GB/home/quiz/results.html%0ADTSTART:20211215T195112Z%0ADTEND:20211215T200612Z%0ASUMMARY:www.AsthmaControlTest.com%0ADESCRIPTION:Retaking%20the%20test%20regularly%20will%20help%20you%20understand%20how%20your%20asthma%20is%20changing%20over%20time.%20We%20recommend%20that%20you%20retake%20the%20test%20monthly.%20That%20way,%20you%20and%20your%20doctor%20can%20work%20together%20to%20keep%20your%20asthma%20under%20control.%0ALOCATION:www.AsthmaControlTest.com%0AEND:VEVENT%0AEND:VCALENDAR"
                                          >
                                            Outlook
                                          </a>
                                        </div>
                                      </a>
                                      <a
                                        href="#"
                                        class="tooltip tooltip-child initialized"
                                        style={{ display: "none" }}
                                      >
                                        Why should I take the test again?
                                        <span class="tooltip-msg">
                                          Retaking the test regularly will help
                                          you understand how your child’s asthma
                                          is changing over time. We suggest that
                                          the test is taken monthly. That way,
                                          you and your doctor can work together
                                          to keep your child´s asthma under
                                          control.
                                        </span>
                                      </a>
                                      <a
                                        href="#"
                                        class="tooltip tooltip-adult initialized"
                                        style={{ display: "inline-block" }}
                                      >
                                        Why should I take the test again?
                                        <span class="tooltip-msg">
                                          Retaking the test regularly will help
                                          you understand how your asthma is
                                          changing over time. We recommend that
                                          you retake the test monthly. That way,
                                          you and your doctor can work together
                                          to keep your asthma under control.
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="gigyaraas component section default-style odd col-xs-12">
                            <div
                              class="gigya-raas"
                              data-api-key=""
                              data-form-type="registration"
                              data-screen-set="dtc-template-LiteRegistration"
                              data-mobile-screen-set=""
                              data-start-screen=""
                              data-component-id="HMYiNNURXOHNqDgYfUCg"
                              data-service-url=""
                              data-resource="/en-gb/quiz/results/"
                              data-lang="en"
                              data-screenset-method="gigyaScreenset"
                              data-success-screen-set=""
                              data-datastore-type=""
                              data-lookup-validate=""
                              data-mdm-validate="false"
                              data-allow-login=""
                              data-resource-path="/content/cf-pharma/asthma-control-test/en_GB/home/quiz/results/jcr:content/Content/gigyaraas_copy"
                              data-auto-login=""
                              data-idp-configs="[]"
                              data-email-verification="UNKNOWN"
                              data-current-node="/content/cf-pharma/asthma-control-test/en_GB/home/quiz/results/jcr:content/Content/gigyaraas_copy"
                              data-screenset-cache=""
                              data-notification-enabled="true"
                              data-registration-attempts="0"
                              data-page-reload="false"
                              data-empty-resource="true"
                              data-registration-page=""
                              data-registration-page-mapping=""
                              data-captcha-enabled="false"
                              data-captcha-type=""
                              data-captcha-validation-msg="Captcha is required"
                              data-error-page=""
                              data-trackingname=""
                              data-gzgevent=""
                              data-gzgscevent=""
                              data-brandid=""
                              data-productid=""
                            >
                              <div id="HMYiNNURXOHNqDgYfUCg"></div>
                              {/* <script>
                                                                if (window.gigyaCustomLang === undefined) {
                                                                    window.gigyaCustomLang = {};
                                       }
                                                                window.gigyaCustomLang['HMYiNNURXOHNqDgYfUCg'] = { };
                                                            </script> */}
                            </div>
                          </div>
                          <div class="gigyaraas component section default-style even last col-xs-12">
                            <div
                              class="gigya-raas"
                              data-api-key=""
                              data-form-type="registration"
                              data-screen-set=""
                              data-mobile-screen-set=""
                              data-start-screen=""
                              data-component-id="dMXwCNzuEZQqxuwUbRxR"
                              data-service-url=""
                              data-resource="/en-gb/quiz/results/"
                              data-lang="en"
                              data-screenset-method="gigyaScreenset"
                              data-success-screen-set=""
                              data-datastore-type=""
                              data-lookup-validate=""
                              data-mdm-validate="false"
                              data-allow-login=""
                              data-resource-path="/content/cf-pharma/asthma-control-test/en_GB/home/quiz/results/jcr:content/Content/gigyaraas"
                              data-auto-login=""
                              data-idp-configs="[]"
                              data-email-verification="UNKNOWN"
                              data-current-node="/content/cf-pharma/asthma-control-test/en_GB/home/quiz/results/jcr:content/Content/gigyaraas"
                              data-screenset-cache=""
                              data-notification-enabled="true"
                              data-registration-attempts="0"
                              data-page-reload="false"
                              data-empty-resource="true"
                              data-registration-page=""
                              data-registration-page-mapping=""
                              data-captcha-enabled="false"
                              data-captcha-type=""
                              data-captcha-validation-msg="Captcha is required"
                              data-error-page=""
                              data-trackingname=""
                              data-gzgevent=""
                              data-gzgscevent=""
                              data-brandid=""
                              data-productid=""
                            >
                              <div id="dMXwCNzuEZQqxuwUbRxR"></div>
                              {/* <script>
                                                                if (window.gigyaCustomLang === undefined) {
                                                                    window.gigyaCustomLang = {};
                                       }
                                                                window.gigyaCustomLang['dMXwCNzuEZQqxuwUbRxR'] = { };
                                                            </script> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="footer" class="col-xs-12">
                <div class="row">
                  <div class="layout-outer">
                    <div class="layout-inner">
                      <div class="col-xs-12 col-md-12 default-style">
                        <div
                          class="box component section default-style first odd last col-xs-12"
                          style={{ padding: 0 }}
                        >
                          <div class="component-content" id="0808245730">
                            <div class="paragraphSystem content">
                              <div class="snippetReference component section footer-section footer-section-legals even last col-xs-12 reference-footer-legals-2">
                                <div class="inner">
                                  <div class="component-content">
                                    <div class="richText component section footer-paragraphs odd">
                                      <div class="component-content">
                                        <div class="richText-content">
                                          <p>
                                            © 2023 My HealthCK. All rights
                                            reserved.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="Footer paragraphSystem"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </body>
      </div>
    );
  }
}

export default withWidth()(index);
