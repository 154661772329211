/**
 * App.js Layout Start Here
 */
import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Loadable from "react-loadable";
import RctPageLoader from "../components/RctPageLoader/RctPageLoader";
import Home from "../routes/Home";
import Sleep from "../routes/Sleep";
import Alcohol from "../routes/Alcohol";
import Welcome from "../routes/Welcome";

import Results from "../routes/Results";
import ResultsSecond from "../routes/ResultsSecond";

import Introduction from "../routes/Introduction/index";
import ChildrenQuiz from "../routes/Children-Quiz/index";
import AdultQuiz from "../routes/Adult-Quiz/index";

const AsyncHomeComponent = Loadable({
  loader: () => import("../routes/Home"),
  loading: () => <RctPageLoader />,
});

const AsyncSleepComponent = Loadable({
  loader: () => import("../routes/Sleep"),
  loading: () => <RctPageLoader />,
});

const AsyncAlcoholComponent = Loadable({
  loader: () => import("../routes/Alcohol"),
  loading: () => <RctPageLoader />,
});

const AsyncWelcomeComponent = Loadable({
  loader: () => import("../routes/Welcome"),
  loading: () => <RctPageLoader />,
});
const AsyncResultsComponent = Loadable({
  loader: () => import("../routes/Results"),
  loading: () => <RctPageLoader />,
});

const AsyncIntroductionComponent = Loadable({
  loader: () => import("../routes/Introduction"),
  loading: () => <RctPageLoader />,
});

const AsyncChildrenQuizComponent = Loadable({
  loader: () => import("../routes/Children-Quiz"),
  loading: () => <RctPageLoader />,
});

const AsyncAdultQuizComponent = Loadable({
  loader: () => import("../routes/Adult-Quiz"),
  loading: () => <RctPageLoader />,
});

const AsyncResultsSecondComponent = Loadable({
  loader: () => import("../routes/ResultsSecond"),
  loading: () => <RctPageLoader />,
});

class App extends Component {
  render() {
    const { location } = this.props;

    if (location.pathname === "/") {
      return <Redirect to={"/home"} />;
    }

    return (
      <Switch>
        <Route path="/home" component={Home} />
        <Route path="/sleepCK" component={Sleep} />
        <Route path="/alcoholCK" component={Alcohol} />
        <Route path="/asthmaCK" component={Welcome} />
        <Route path="/result" component={Results} />
        <Route path="/quiz-result" component={ResultsSecond} />
        <Route path="/child" component={Introduction} />
        <Route path="/children-quiz" component={ChildrenQuiz} />
        <Route path="/adult" component={AdultQuiz} />
      </Switch>
    );
  }
}

export default App;
