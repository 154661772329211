import React, { Component } from "react";
import logo from "../../assets/Welcome/logo.png";
import "../../assets/css/bootstrap.css";

import child from "../../assets/Welcome/icon-Adult-Child-green (1).svg";
import adultChild from "../../assets/Welcome/Adult-child-icon.svg";

class index extends React.Component {
  state = {};

  handleBack() {
    const { history } = this.props;
    history.goBack();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleChildQuiz() {
    const { history } = this.props;
    history.push("/children-quiz");
  }

  render() {
    const { history } = this.props;
    const { fields, errors } = this.state;

    return (
      <body class="page-quiz page-introduction  layout-bootstrap-layout-12 template-branded-with-claims grid-bootstrap header-height-2 initialized">
        <div>
          <div class="row">
            <div id="header" class="col-xs-12">
              <div class="row">
                <div class="layout-outer">
                  <div class="layout-inner">
                    <div class="col-xs-12 col-md-12 default-style">
                      <a
                        id="960044208"
                        style={{ visibility: "hidden" }}
                        aria-hidden="true"
                      ></a>
                      <div class="box component section content-container header-container height-variant-1 first odd last col-xs-12 col-xs-offset-0 col-md-12 col-md-offset-0">
                        <div class="component-content" id="01995300884">
                          <div class="paragraphSystem content">
                            <a
                              id="01700272810"
                              style={{ visibility: "hidden" }}
                              aria-hidden="true"
                            ></a>
                            <div class="snippetReference component section default-style first odd col-xs-6 reference-brand-area">
                              <div class="inner">
                                <div class="component-content">
                                  <a
                                    id="0597899827"
                                    style={{ visibility: "hidden" }}
                                    aria-hidden="true"
                                  ></a>
                                  <div class="richTextEnhanced richText section richTextHyperlinkedImage default-style first odd last component">
                                    <div class="component-content">
                                      <a href="/home">
                                        {" "}
                                        <img
                                          width="180"
                                          src={logo}
                                          class="richText-image pull-left"
                                          alt=""
                                        />
                                      </a>
                                      <div class="richText-content">
                                        <ph></ph>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="Header paragraphSystem"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="content" style={{ minHeight: 160 }} class="col-xs-12">
              <div class="row">
                <div class="layout-outer">
                  <div class="layout-inner">
                    <div class="col-xs-12 col-md-12 default-style">
                      <div class="Content paragraphSystem">
                        <a
                          id="01302328484"
                          style={{ visibility: "hidden" }}
                          aria-hidden="true"
                        ></a>
                        <div class="box component section header-overlay first odd col-xs-12">
                          <div class="component-content" id="01506593398">
                            <div class="paragraphSystem content">
                              <a
                                id="639663348"
                                style={{ visibility: "hidden" }}
                                aria-hidden="true"
                              ></a>
                              <div class="richText component section move-top main-header first odd col-xs-12">
                                <div class="component-content">
                                  <div class="richText-content">
                                    <h1>Introduction</h1>
                                  </div>
                                </div>
                              </div>
                              <div class="richText component section desktop-only header-previous-nav previous-nav-link even last col-xs-12">
                                <div class="component-content">
                                  <div
                                    onClick={this.handleBack.bind(this)}
                                    class="richText-content"
                                  >
                                    <p>
                                      <a>Back to previous page</a>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="box component section content-container even last col-xs-12 col-xs-offset-0 col-md-12 col-md-offset-0">
                          <div class="component-content" id="1137830707">
                            <div class="paragraphSystem content">
                              <a
                                id="01989707363"
                                style={{ visibility: "hidden" }}
                                aria-hidden="true"
                              ></a>
                              <div class="box component section content-container first odd last col-xs-12">
                                <div class="component-content" id="800996953">
                                  <div class="paragraphSystem content">
                                    <a
                                      id="075938109"
                                      style={{ visibility: "hidden" }}
                                      aria-hidden="true"
                                    ></a>
                                    <div class="box component section quiz-part first odd col-xs-12">
                                      <div
                                        class="component-content"
                                        id="2084112511"
                                      >
                                        <div class="paragraphSystem content">
                                          <a
                                            id="800230121"
                                            style={{ visibility: "hidden" }}
                                            aria-hidden="true"
                                          ></a>
                                          <div class="richText component section quiz-part-header first odd col-xs-12">
                                            <div class="component-content">
                                              <div class="richText-content">
                                                <h2>PART 1</h2>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="richText component section quiz-part-desc even col-xs-12">
                                            <div class="component-content">
                                              <div class="richText-content">
                                                <h3>Adult and Child</h3>
                                                <p>
                                                  Step 1: Let your child respond
                                                  to the first four questions (1
                                                  to 4). If your child needs
                                                  help in reading or
                                                  understanding the question,
                                                  you may help, but let your
                                                  child select the response.
                                                  <br />
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="richText component section quiz-part-banner odd last col-xs-12">
                                            <div class="component-content">
                                              <img
                                                src={child}
                                                class="richText-image pull-right"
                                                alt="Adults with children"
                                                title="Adults with children"
                                              />
                                              <div class="richText-content">
                                                <p>4</p>
                                                <p>QUESTIONS</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="divider component section narrow-divider even col-xs-12">
                                      <div class="component-content"></div>
                                    </div>
                                    <div class="box component section quiz-part odd col-xs-12">
                                      <div
                                        class="component-content"
                                        id="01001597131"
                                      >
                                        <div class="paragraphSystem content">
                                          <a
                                            id="784286623"
                                            style={{ visibility: "hidden" }}
                                            aria-hidden="true"
                                          ></a>
                                          <div class="richText component section quiz-part-header first odd col-xs-12">
                                            <div class="component-content">
                                              <div class="richText-content">
                                                <h2>PART 2</h2>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="richText component section quiz-part-desc even col-xs-12">
                                            <div class="component-content">
                                              <div class="richText-content">
                                                <h3>Adult</h3>
                                                <p>
                                                  Step 2: Complete the remaining
                                                  three questions (5 to 7) on
                                                  your own and without letting
                                                  your child's responses
                                                  influence your answers. There
                                                  are no right or wrong answers.
                                                </p>
                                                <p>
                                                  <br /> Step 3: Take a
                                                  print-out of the test results
                                                  to the doctor to talk about
                                                  your child's total score.
                                                  <br />
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="richText component section quiz-part-banner odd last col-xs-12">
                                            <div class="component-content">
                                              <img
                                                src={adultChild}
                                                class="richText-image pull-right"
                                                alt="Adult No Children"
                                                title="Adult No Children"
                                              />
                                              <div class="richText-content">
                                                <p>3</p>
                                                <p>QUESTIONS</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="box component section quiz-nav even last col-xs-12">
                                      <div
                                        class="component-content"
                                        id="498764687"
                                      >
                                        <div class="paragraphSystem content">
                                          <a
                                            id="155713017"
                                            style={{ visibility: "hidden" }}
                                            aria-hidden="true"
                                          ></a>
                                          <div class="richText component section previous-nav-link first odd col-xs-12">
                                            <div
                                              onClick={this.handleBack.bind(
                                                this
                                              )}
                                              class="component-content"
                                            >
                                              <div class="richText-content">
                                                <p>
                                                  <a>Back to previous page</a>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="richText component section nav-arrow-right nav-button nav-button-arrow-right even last col-xs-12">
                                            <div class="component-content">
                                              <div class="richText-content">
                                                <p>
                                                  <a
                                                    onClick={this.handleChildQuiz.bind(
                                                      this
                                                    )}
                                                  >
                                                    TAKE ME TO PART 1
                                                  </a>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="footer" class="col-xs-12">
              <div class="row">
                <div class="layout-outer">
                  <div class="layout-inner">
                    <div class="col-xs-12 col-md-12 default-style">
                      <div
                        class="box component section default-style first odd last col-xs-12"
                        style={{ padding: 0 }}
                      >
                        <div class="component-content" id="0808245730">
                          <div class="paragraphSystem content">
                            <div class="snippetReference component section footer-section footer-section-legals even last col-xs-12 reference-footer-legals-2">
                              <div class="inner">
                                <div class="component-content">
                                  <div class="richText component section footer-paragraphs odd">
                                    <div class="component-content">
                                      <div class="richText-content">
                                        <p>
                                          © 2023 My HealthCK. All rights
                                          reserved.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="Footer paragraphSystem"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    );
  }
}

export default index;
