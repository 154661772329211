import React, { Component } from "react";
// import './bootstrap.css'
import timeLine from "../../assets/Welcome/icon_Time_line.png";
import answers from "../../assets/Welcome/icon_Answers_line.png";
import hcp from "../../assets/Welcome/icon_HCP_line.png";
import childIcon from "../../assets/Welcome/Child-icon.svg";
import adultsIcon from "../../assets/Welcome/Adults-icon.svg";
import homebg from "../../assets/Welcome/home_bg.png";
import logo from "../../assets/Welcome/logo.png";
import "../../assets/css/bootstrap.css";

import Cookies from "universal-cookie";

const cookies = new Cookies();

class index extends React.Component {
  state = {};

  handleAdult() {
    const { history } = this.props;
    cookies.set("selectedTest", "adult");

    history.push("/adult");
  }

  handleChild() {
    const { history } = this.props;
    cookies.set("selectedTest", "child");
    history.push("/child");
  }

  componentDidMount() {
    document.title = "Asthama | Test Screening";
  }

  render() {
    const { history } = this.props;
    const { fields, errors } = this.state;

    return (
      <body class="page-welcome  layout-bootstrap-layout-12 template-branded-with-claims grid-bootstrap header-height-1 initialized">
        <div style={{ position: "relative", overflow: "hidden" }}>
          <div class="row">
            <div id="header" class="col-xs-12">
              <div class="row">
                <div class="layout-outer">
                  <div class="layout-inner">
                    <div class="col-xs-12 col-md-12 default-style">
                      <a
                        id="960044208"
                        style={{ visibility: "hidden" }}
                        aria-hidden="true"
                      ></a>
                      <div class="box component section content-container header-container height-variant-1 first odd last col-xs-12 col-xs-offset-0 col-md-12 col-md-offset-0">
                        <div class="component-content" id="01995300884">
                          <div class="paragraphSystem content">
                            <a
                              id="01700272810"
                              style={{ visibility: "hidden" }}
                              aria-hidden="true"
                            ></a>
                            <div class="snippetReference component section default-style first odd col-xs-6 reference-brand-area">
                              <div class="inner">
                                <div class="component-content">
                                  <a
                                    id="0597899827"
                                    style={{ visibility: "hidden" }}
                                    aria-hidden="true"
                                  ></a>
                                  <div class="richTextEnhanced richText section richTextHyperlinkedImage default-style first odd last component">
                                    <div class="component-content">
                                      <a href="/home">
                                        {" "}
                                        <img
                                          width="180"
                                          src={logo}
                                          class="richText-image pull-left"
                                          alt=""
                                        />
                                      </a>
                                      <div class="richText-content">
                                        <ph></ph>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="Header paragraphSystem"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="content" class="col-xs-12" style={{ minHeight: 827 }}>
              <div class="row">
                <div class="layout-outer">
                  <div class="layout-inner">
                    <div class="col-xs-12 col-md-12 default-style">
                      <div class="Content paragraphSystem">
                        <a
                          id="777783402"
                          style={{ visibility: "hidden" }}
                          aria-hidden="true"
                        ></a>
                        <div class="box component section header-overlay first odd col-xs-12">
                          <div class="component-content" id="0789560808">
                            <div class="paragraphSystem content">
                              <a
                                id="01224270974"
                                style={{ visibility: "hidden" }}
                                aria-hidden="true"
                              ></a>
                              <div class="richText component section move-top main-header first odd col-xs-12">
                                <div class="component-content">
                                  <div class="richText-content">
                                    <h1>
                                      Welcome to the{" "}
                                      <span class="no-wrap">AsthmaCK</span>
                                    </h1>
                                  </div>
                                </div>
                              </div>
                              <div class="richText component section secondary-header even last col-xs-12">
                                <div class="component-content">
                                  <div class="richText-content">
                                    {/* <p><strong>Asthma is a common and treatable disease</strong></p>
                                                                        <p>It cannot be cured, but it can be managed and doesn´t have to slow you down</p> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="box component section content-container even last col-xs-12 col-xs-offset-0 col-md-12 col-md-offset-0">
                          <div class="component-content" id="991188154">
                            <div class="paragraphSystem content">
                              <a
                                id="1615293476"
                                style={{ visibility: "hidden" }}
                                aria-hidden="true"
                              ></a>
                              <div class="box component section default-style first odd last col-xs-12">
                                <div class="component-content" id="0658270880">
                                  <div class="paragraphSystem content">
                                    <a
                                      id="521578186"
                                      style={{ visibility: "hidden" }}
                                      aria-hidden="true"
                                    ></a>
                                    <div class="box component section horizontal-list first odd col-xs-12">
                                      <div
                                        class="component-content"
                                        id="0762770170"
                                      >
                                        <div class="paragraphSystem content">
                                          <a
                                            id="2010454128"
                                            style={{ visibility: "hidden" }}
                                            aria-hidden="true"
                                          ></a>
                                          <div class="richText component section top-image-card first odd col-xs-12">
                                            <div class="component-content">
                                              <img
                                                src={timeLine}
                                                style={{ height: "auto" }}
                                                class="richText-image "
                                                alt=""
                                              />
                                              <div class="richText-content">
                                                <h3>
                                                  A Simple Test to Help Manage
                                                  Your Asthma
                                                </h3>
                                                <p>
                                                  The AsthmaCK is a commonly
                                                  used tool by healthcare
                                                  providers globally, and has
                                                  been scientifically tested
                                                  with hundreds of people with
                                                  asthma
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="richText component section horizontal-list-element top-image-card even col-xs-12">
                                            <div class="component-content">
                                              <img
                                                src={answers}
                                                style={{ height: "auto" }}
                                                class="richText-image "
                                                alt=""
                                              />
                                              <div class="richText-content">
                                                <h3>
                                                  Your Answers Create Actionable
                                                  Results
                                                </h3>
                                                <p>
                                                  The AsthmaCK is a way to help
                                                  you and your healthcare
                                                  provider determine if your
                                                  asthma symptoms are well
                                                  controlled
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="richText component section top-image-card odd last col-xs-12">
                                            <div class="component-content">
                                              <img
                                                src={hcp}
                                                style={{ height: "auto" }}
                                                class="richText-image "
                                                alt=""
                                              />
                                              <div class="richText-content">
                                                <h3>
                                                  Take the Test and Take the
                                                  Results to Your Doctor
                                                </h3>
                                                <p>
                                                  Based on your score, your
                                                  healthcare provider can help
                                                  you take appropriate action to
                                                  better manage your asthma
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="divider component section default-style even col-xs-12">
                                      <div class="component-content"></div>
                                    </div>
                                    <div class="richText component section default-style odd col-xs-12">
                                      <div class="component-content">
                                        <div class="richText-content">
                                          <h2>Select your test</h2>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="richTextEnhanced richText section richTextHyperlinkedImage quiz-card child-card even component col-xs-12 col-md-6 col-sm-5">
                                      <div
                                        onClick={this.handleChild.bind(this)}
                                        class="component-content"
                                      >
                                        <a>
                                          {" "}
                                          <img
                                            src={childIcon}
                                            class="richText-image pull-left"
                                            alt=""
                                          />
                                        </a>
                                        <div class="richText-content">
                                          <h3>Child</h3>
                                          <h4>4-11 years</h4>
                                          <p>
                                            7 questions
                                            <br /> Duration: 5 minutes
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="richTextEnhanced richText section richTextHyperlinkedImage quiz-card adult-card odd last component col-xs-12 col-md-6 col-sm-5">
                                      <div
                                        onClick={this.handleAdult.bind(this)}
                                        class="component-content"
                                      >
                                        <a>
                                          {" "}
                                          <img
                                            src={adultsIcon}
                                            class="richText-image pull-left"
                                            alt=""
                                          />
                                        </a>
                                        <div class="richText-content">
                                          <h3>Adult</h3>
                                          <h4>12+ years</h4>
                                          <p>
                                            5 questions
                                            <br /> Duration: 1 minute
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="footer" class="col-xs-12">
              <div class="row">
                <div class="layout-outer">
                  <div class="layout-inner">
                    <div class="col-xs-12 col-md-12 default-style">
                      <div
                        class="box component section default-style first odd last col-xs-12"
                        style={{ padding: 0 }}
                      >
                        <div class="component-content" id="0808245730">
                          <div class="paragraphSystem content">
                            <div class="snippetReference component section footer-section footer-section-legals even last col-xs-12 reference-footer-legals-2">
                              <div class="inner">
                                <div class="component-content">
                                  <div class="richText component section footer-paragraphs odd">
                                    <div class="component-content">
                                      <div class="richText-content">
                                        <p>
                                          © 2023 My HealthCK. All rights
                                          reserved.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="Footer paragraphSystem"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="hide-bg-image-ph"
            style={{
              position: "absolute",
              top: 0,
              left: -115,
              right: 0,
              opacity: 0.1,
            }}
          >
            <img style={{ width: "100%" }} src={homebg} />
          </div>
        </div>
      </body>
    );
  }
}

export default index;
