import React, { Component } from "react";
import logo from "../../assets/Welcome/logo.png";

import page from "../../assets/Welcome/pagesidegraphics.png";
import "../../assets/css/bootstrap.css";
import Cookies from "universal-cookie";

const cookies = new Cookies();

class index extends React.Component {
  state = {
    adultQuestionone: [
      {
        id: "1",
        title: "All of the time",
        question:
          "During the last 4 weeks how much of the time has your asthma kept you from getting as much done at work, school or home?",
        points: "1",
      },
      {
        id: "1",
        title: "Most of the time",
        question:
          "During the last 4 weeks how much of the time has your asthma kept you from getting as much done at work, school or home?",
        points: "2",
      },
      {
        id: "1",
        title: "Some of the time",
        question:
          "During the last 4 weeks how much of the time has your asthma kept you from getting as much done at work, school or home?",
        points: "3",
      },
      {
        id: "1",
        title: "A little of the time",
        question:
          "During the last 4 weeks how much of the time has your asthma kept you from getting as much done at work, school or home?",
        points: "4",
      },
      {
        id: "1",
        title: "None of the time",
        question:
          "During the last 4 weeks how much of the time has your asthma kept you from getting as much done at work, school or home?",
        points: "5",
      },
    ],
    adultQuestionTwo: [
      {
        id: "1",
        title: "More than once a day",
        question:
          "During the last 4 weeks how often have you had shortness of breath?",
        points: "1",
      },
      {
        id: "1",
        title: "Once a day",
        question:
          "During the last 4 weeks how often have you had shortness of breath?",
        points: "2",
      },
      {
        id: "1",
        title: "3 to 6 times a week",
        question:
          "During the last 4 weeks how often have you had shortness of breath?",
        points: "3",
      },
      {
        id: "1",
        title: "Once or twice a week",
        question:
          "During the last 4 weeks how often have you had shortness of breath?",
        points: "4",
      },
      {
        id: "1",
        title: "Not at all",
        question:
          "During the last 4 weeks how often have you had shortness of breath?",
        points: "5",
      },
    ],
    adultQuestionThree: [
      {
        id: "1",
        title: "4 or more nights a week",
        points: "1",

        question:
          "During the last 4 weeks how often have your asthma symptoms (wheezing, coughing, shortness of breath, chest tightness or pain) woken you up at night or earlier than usual in the morning?",
      },
      {
        id: "1",
        title: "2 to 3 nights a week",
        points: "2",

        question:
          "During the last 4 weeks how often have your asthma symptoms (wheezing, coughing, shortness of breath, chest tightness or pain) woken you up at night or earlier than usual in the morning?",
      },
      {
        id: "1",
        title: "Once a week",
        points: "3",

        question:
          "During the last 4 weeks how often have your asthma symptoms (wheezing, coughing, shortness of breath, chest tightness or pain) woken you up at night or earlier than usual in the morning?",
      },
      {
        id: "1",
        title: "Once or Twice",
        points: "4",

        question:
          "During the last 4 weeks how often have your asthma symptoms (wheezing, coughing, shortness of breath, chest tightness or pain) woken you up at night or earlier than usual in the morning?",
      },
      {
        id: "1",
        title: "Not at all",
        points: "5",

        question:
          "During the last 4 weeks how often have your asthma symptoms (wheezing, coughing, shortness of breath, chest tightness or pain) woken you up at night or earlier than usual in the morning?",
      },
    ],
    adultQuestionFour: [
      {
        id: "1",
        title: "3 or more times per day",
        question:
          "During the last 4 weeks, how often have you used your rescue inhaler or nebuliser medication (such as Salbutamol)?",
        points: "1",
      },
      {
        id: "1",
        title: "Once or twice per day",
        question:
          "During the last 4 weeks, how often have you used your rescue inhaler or nebuliser medication (such as Salbutamol)?",
        points: "2",
      },
      {
        id: "1",
        title: "2 or 3 times per week",
        question:
          "During the last 4 weeks, how often have you used your rescue inhaler or nebuliser medication (such as Salbutamol)?",
        points: "3",
      },
      {
        id: "1",
        title: "Once a week or less",
        question:
          "During the last 4 weeks, how often have you used your rescue inhaler or nebuliser medication (such as Salbutamol)?",
        points: "4",
      },
      {
        id: "1",
        title: "Not at all",
        question:
          "During the last 4 weeks, how often have you used your rescue inhaler or nebuliser medication (such as Salbutamol)?",
        points: "5",
      },
    ],
    adultQuestionFive: [
      {
        id: "1",
        title: "Not Controlled at all",
        question:
          "How would you rate your asthma control during the last 4 weeks?",
        points: "1",
      },
      {
        id: "1",
        title: "Poorly Controlled",
        question:
          "How would you rate your asthma control during the last 4 weeks?",
        points: "2",
      },
      {
        id: "1",
        title: "Somewhat Controlled",
        question:
          "How would you rate your asthma control during the last 4 weeks?",
        points: "3",
      },
      {
        id: "1",
        title: "Well Controlled",
        question:
          "How would you rate your asthma control during the last 4 weeks?",
        points: "4",
      },
      {
        id: "1",
        title: "Completely Controlled",
        question:
          "How would you rate your asthma control during the last 4 weeks?",
        points: "5",
      },
    ],
    adultFirstId: {},
    adultSecondId: {},
    adultThirdId: {},
    adultFourthId: {},
    adultFifthId: {},

    tabFirst: false,
    tabSecond: false,
    tabThird: false,
    tabFourth: false,

    selectedFirstArry: [],
    selectedSecondArry: [],
    selectedThirdArry: [],
    selectedFourArry: [],
    selectedFiveArry: [],

    adultQuizArry: [],
  };

  handleBack() {
    const { history } = this.props;
    history.goBack();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handlResult() {
    const {
      selectedFirstArry,
      selectedSecondArry,
      selectedThirdArry,
      selectedFourArry,
      selectedFiveArry,
      adultQuizArry,
    } = this.state;

    adultQuizArry.push(selectedFirstArry);
    adultQuizArry.push(selectedSecondArry);
    adultQuizArry.push(selectedThirdArry);
    adultQuizArry.push(selectedFourArry);

    adultQuizArry.push(selectedFiveArry);

    console.log(JSON.stringify(adultQuizArry));

    cookies.set("adultQuiz", adultQuizArry);
    const { history } = this.props;
    history.push("/result");
  }

  handleQuestionOne(obj) {
    const { adultFirstId, adultQuizArry, selectedFirstArry } = this.state;

    const index = selectedFirstArry.indexOf(obj);

    selectedFirstArry.splice(index, 1);
    if (index > -1) {
    } else {
      selectedFirstArry.push(obj);
    }
    this.setState({
      selectedFirstArry: selectedFirstArry,
      tabFirst: true,
      adultFirstId: obj.title,
    });
  }
  handleQuestionTwo(obj) {
    const { adultFirstId, adultQuizArry, selectedSecondArry } = this.state;

    const index = selectedSecondArry.indexOf(obj);

    selectedSecondArry.splice(index, 1);
    if (index > -1) {
    } else {
      selectedSecondArry.push(obj);
    }
    this.setState({
      selectedSecondArry: selectedSecondArry,
      tabSecond: true,
      adultSecondId: obj.title,
    });
  }
  handleQuestionThree(obj) {
    const { adultFirstId, adultQuizArry, selectedThirdArry } = this.state;

    const index = selectedThirdArry.indexOf(obj);

    selectedThirdArry.splice(index, 1);
    if (index > -1) {
    } else {
      selectedThirdArry.push(obj);
    }
    this.setState({
      selectedThirdArry: selectedThirdArry,
      tabThird: true,
      adultThirdId: obj.title,
    });
  }
  handleQuestionFour(obj) {
    const { adultFirstId, adultQuizArry, selectedFourArry } = this.state;

    const index = selectedFourArry.indexOf(obj);

    selectedFourArry.splice(index, 1);
    if (index > -1) {
    } else {
      selectedFourArry.push(obj);
    }
    this.setState({
      selectedFourArry: selectedFourArry,
      tabFourth: true,
      adultFourthId: obj.title,
    });
  }
  handleQuestionFive(obj) {
    const { adultFirstId, adultQuizArry, selectedFiveArry } = this.state;

    const index = selectedFiveArry.indexOf(obj);

    selectedFiveArry.splice(index, 1);
    if (index > -1) {
    } else {
      selectedFiveArry.push(obj);
    }
    this.setState({
      selectedFiveArry: selectedFiveArry,
      adultFifthId: obj.title,
    });
  }

  render() {
    const { history } = this.props;
    const {
      fields,
      errors,
      adultQuestionone,
      adultQuestionFour,
      adultQuizArry,
      adultQuestionTwo,
      adultQuestionFive,
      adultQuestionThree,
      adultFirstId,
      adultSecondId,
      adultThirdId,
      adultFourthId,
      adultFifthId,

      tabFirst,
      tabSecond,
      tabThird,
      tabFourth,
      selectedFiveArry,
    } = this.state;

    console.log(JSON.stringify(adultQuizArry));

    return (
      <div>
        <body class="page-quiz page-adult-quiz  layout-bootstrap-layout-12 template-branded-with-claims grid-bootstrap header-height-4 initialized">
          <div>
            <div class="row">
              <div id="header" class="col-xs-12">
                <div class="row">
                  <div class="layout-outer">
                    <div class="layout-inner">
                      <div class="col-xs-12 col-md-12 default-style">
                        <a
                          id="960044208"
                          style={{ visibility: "hidden" }}
                          aria-hidden="true"
                        ></a>
                        <div class="box component section content-container header-container height-variant-1 first odd last col-xs-12 col-xs-offset-0 col-md-12 col-md-offset-0">
                          <div class="component-content" id="01995300884">
                            <div class="paragraphSystem content">
                              <a
                                id="01700272810"
                                style={{ visibility: "hidden" }}
                                aria-hidden="true"
                              ></a>
                              <div class="snippetReference component section default-style first odd col-xs-6 reference-brand-area">
                                <div class="inner">
                                  <div class="component-content">
                                    <a
                                      id="0597899827"
                                      style={{ visibility: "hidden" }}
                                      aria-hidden="true"
                                    ></a>
                                    <div class="richTextEnhanced richText section richTextHyperlinkedImage default-style first odd last component">
                                      <div class="component-content">
                                        <a href="/home">
                                          {" "}
                                          <img
                                            width="180"
                                            src={logo}
                                            class="richText-image pull-left"
                                            alt=""
                                          />
                                        </a>
                                        <div class="richText-content">
                                          <ph></ph>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="Header paragraphSystem"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="content" class="col-xs-12" style={{ minHeight: 139 }}>
                <div class="row">
                  <div class="layout-outer">
                    <div class="layout-inner">
                      <div class="col-xs-12 col-md-12 default-style">
                        <div class="Content paragraphSystem">
                          <a
                            id="225138314"
                            style={{ visibility: "hidden" }}
                            aria-hidden="true"
                          ></a>
                          <div
                            class="box component section header-overlay first odd col-xs-12"
                            style={{ overflow: "hidden", position: "relative" }}
                          >
                            <div class="component-content" id="01081321416">
                              <div class="paragraphSystem content">
                                <a
                                  id="01279585886"
                                  style={{ visibility: "hidden" }}
                                  aria-hidden="true"
                                ></a>
                                <div class="richText component section header-previous-nav previous-nav-link desktop-only first odd last col-xs-12">
                                  <div class="component-content mt-4">
                                    <div
                                      onClick={this.handleBack.bind(this)}
                                      class="richText-content"
                                    >
                                      <p>
                                        <a>Back to previous page</a>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="box component section content-container content-wide even last col-xs-12 col-xs-offset-0 col-md-12 col-md-offset-0">
                            <div class="component-content" id="660277445">
                              <div class="paragraphSystem content">
                                <a
                                  id="596305199"
                                  style={{ visibility: "hidden" }}
                                  aria-hidden="true"
                                ></a>
                                <div class="quiz component section adult-quiz first odd col-xs-12 initialized pos-rel">
                                  <div class="hide-bg-image-ph ref-container"></div>
                                  <div
                                    class="hide-bg-image-ph"
                                    style={{
                                      position: "fixed",
                                      bottom: -74,
                                      zIndex: 2,
                                      textAlign: "right",
                                      right: -74,
                                      opacity: 0.4,
                                    }}
                                  >
                                    <img style={{ width: "100" }} src={page} />
                                  </div>
                                  <div class="component quiz initialized inherit-sec">
                                    <div
                                      class="quizPrimaryContainer "
                                      data-quiz-type="redirect"
                                      data-score-display="false"
                                    >
                                      <div class="quiz questions initialized">
                                        <div class="questionsPaginationContainer">
                                          <div
                                            class="questionsPerPage"
                                            id="page1"
                                          >
                                            <div class="questionAnswers paragraphSystem">
                                              <a
                                                id="2027627271"
                                                style={{ visibility: "hidden" }}
                                                aria-hidden="true"
                                              ></a>

                                              <div class="questionanswers component section act-box first odd">
                                                <div class="questionanswers-container">
                                                  <div class="quiz questionWithRadio component initialized">
                                                    <div class="component-content">
                                                      <div class="quizQuestion">
                                                        <p class="quesLeftCaption">
                                                          Question 1{" "}
                                                        </p>
                                                        <p>
                                                          During the{" "}
                                                          <u>last 4 weeks</u>,
                                                          how much of the time
                                                          has your asthma kept
                                                          you from getting as
                                                          much done at work,
                                                          school or home?
                                                        </p>
                                                        <p></p>
                                                        <div
                                                          class="imgContainer"
                                                          id="question0"
                                                        >
                                                          <div class="genericFormGroup">
                                                            {adultQuestionone.map(
                                                              (obj) => (
                                                                <>
                                                                  <span class="formControl-radio">
                                                                    {" "}
                                                                    <input
                                                                      type="radio"
                                                                      id="adult-1-answer1"
                                                                      data-weightage="1"
                                                                      name="adult-1"
                                                                    />{" "}
                                                                  </span>
                                                                  <label
                                                                    onClick={this.handleQuestionOne.bind(
                                                                      this,
                                                                      obj
                                                                    )}
                                                                    style={{
                                                                      backgroundColor:
                                                                        adultFirstId ==
                                                                        obj.title
                                                                          ? "#358dbe"
                                                                          : "",

                                                                      color:
                                                                        adultFirstId ==
                                                                        obj.title
                                                                          ? "#FFFFFF"
                                                                          : "",
                                                                    }}
                                                                    for="adult-1-answer1"
                                                                  >
                                                                    {obj.title}
                                                                  </label>
                                                                </>
                                                              )
                                                            )}
                                                          </div>
                                                          <div
                                                            class="quizScoreBox"
                                                            id="question0score"
                                                          >
                                                            <p></p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="clear"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class={
                                                  tabFirst == true
                                                    ? "questionanswers component section act-box first odd"
                                                    : "questionanswers component section act-box"
                                                }
                                              >
                                                <div class="questionanswers-container">
                                                  <div class="quiz questionWithRadio component initialized">
                                                    <div class="component-content">
                                                      <div class="quizQuestion">
                                                        <p class="quesLeftCaption">
                                                          Question 2{" "}
                                                        </p>
                                                        <p>
                                                          During the{" "}
                                                          <u>last 4 weeks</u>,
                                                          how often have you had
                                                          shortness of breath?
                                                        </p>
                                                        <p></p>
                                                        <div
                                                          class="imgContainer"
                                                          id="question1"
                                                        >
                                                          <div class="genericFormGroup">
                                                            {adultQuestionTwo.map(
                                                              (obj) => (
                                                                <>
                                                                  <span class="formControl-radio">
                                                                    {" "}
                                                                    <input
                                                                      type="radio"
                                                                      id="adult-2-answer1"
                                                                      data-weightage="1"
                                                                      name="adult-2"
                                                                    />{" "}
                                                                  </span>
                                                                  <label
                                                                    onClick={this.handleQuestionTwo.bind(
                                                                      this,
                                                                      obj
                                                                    )}
                                                                    style={{
                                                                      backgroundColor:
                                                                        adultSecondId ==
                                                                        obj.title
                                                                          ? "#358dbe"
                                                                          : "",

                                                                      color:
                                                                        adultSecondId ==
                                                                        obj.title
                                                                          ? "#FFFFFF"
                                                                          : "",
                                                                    }}
                                                                    for="adult-2-answer1"
                                                                  >
                                                                    {obj.title}
                                                                  </label>
                                                                </>
                                                              )
                                                            )}
                                                          </div>

                                                          <div
                                                            class="quizScoreBox"
                                                            id="question1score"
                                                          >
                                                            <p></p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="clear"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div
                                                class={
                                                  tabSecond == true
                                                    ? "questionanswers component section act-box first odd"
                                                    : "questionanswers component section act-box"
                                                }
                                              >
                                                <div class="questionanswers-container">
                                                  <div class="quiz questionWithRadio component initialized">
                                                    <div class="component-content">
                                                      <div class="quizQuestion">
                                                        <p class="quesLeftCaption">
                                                          Question 3{" "}
                                                        </p>
                                                        <p>
                                                          During the{" "}
                                                          <u>last 4 weeks</u>,
                                                          how often have your
                                                          asthma symptoms
                                                          (wheezing, coughing,
                                                          shortness of breath,
                                                          chest tightness or
                                                          pain) woken you up at
                                                          night or earlier than
                                                          usual in the morning?
                                                        </p>
                                                        <p></p>
                                                        <div
                                                          class="imgContainer"
                                                          id="question2"
                                                        >
                                                          <div class="genericFormGroup">
                                                            {adultQuestionThree.map(
                                                              (obj) => (
                                                                <>
                                                                  <span class="formControl-radio">
                                                                    {" "}
                                                                    <input
                                                                      type="radio"
                                                                      id="adult-3-answer1"
                                                                      data-weightage="1"
                                                                      name="adult-3"
                                                                    />{" "}
                                                                  </span>
                                                                  <label
                                                                    onClick={this.handleQuestionThree.bind(
                                                                      this,
                                                                      obj
                                                                    )}
                                                                    style={{
                                                                      backgroundColor:
                                                                        adultThirdId ==
                                                                        obj.title
                                                                          ? "#358dbe"
                                                                          : "",

                                                                      color:
                                                                        adultThirdId ==
                                                                        obj.title
                                                                          ? "#FFFFFF"
                                                                          : "",
                                                                    }}
                                                                    for="adult-3-answer1"
                                                                  >
                                                                    {obj.title}
                                                                  </label>
                                                                </>
                                                              )
                                                            )}
                                                          </div>

                                                          <div
                                                            class="quizScoreBox"
                                                            id="question2score"
                                                          >
                                                            <p></p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="clear"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class={
                                                  tabThird == true
                                                    ? "questionanswers component section act-box first odd"
                                                    : "questionanswers component section act-box"
                                                }
                                              >
                                                <div class="questionanswers-container">
                                                  <div class="quiz questionWithRadio component initialized">
                                                    <div class="component-content">
                                                      <div class="quizQuestion">
                                                        <p class="quesLeftCaption">
                                                          Question 4{" "}
                                                        </p>
                                                        <p>
                                                          During the{" "}
                                                          <u>last 4 weeks</u>,
                                                          how often have you
                                                          used your rescue
                                                          inhaler or nebuliser
                                                          medication (such as
                                                          Salbutamol)?
                                                        </p>
                                                        <p></p>
                                                        <div
                                                          class="imgContainer"
                                                          id="question3"
                                                        >
                                                          <div class="genericFormGroup">
                                                            {adultQuestionFour.map(
                                                              (obj) => (
                                                                <>
                                                                  <span class="formControl-radio">
                                                                    {" "}
                                                                    <input
                                                                      type="radio"
                                                                      id="adult-4-answer1"
                                                                      data-weightage="1"
                                                                      name="adult-4"
                                                                    />{" "}
                                                                  </span>
                                                                  <label
                                                                    onClick={this.handleQuestionFour.bind(
                                                                      this,
                                                                      obj
                                                                    )}
                                                                    style={{
                                                                      backgroundColor:
                                                                        adultFourthId ==
                                                                        obj.title
                                                                          ? "#358dbe"
                                                                          : "",

                                                                      color:
                                                                        adultFourthId ==
                                                                        obj.title
                                                                          ? "#FFFFFF"
                                                                          : "",
                                                                    }}
                                                                    for="adult-4-answer1"
                                                                  >
                                                                    {obj.title}
                                                                  </label>
                                                                </>
                                                              )
                                                            )}
                                                          </div>

                                                          <div
                                                            class="quizScoreBox"
                                                            id="question3score"
                                                          >
                                                            <p></p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="clear"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class={
                                                  tabFourth == true
                                                    ? "questionanswers component section act-box first odd"
                                                    : "questionanswers component section act-box"
                                                }
                                              >
                                                <div class="questionanswers-container">
                                                  <div class="quiz questionWithRadio component initialized">
                                                    <div class="component-content">
                                                      <div class="quizQuestion">
                                                        <p class="quesLeftCaption">
                                                          Question 5{" "}
                                                        </p>
                                                        <p>
                                                          How would you rate
                                                          your asthma control
                                                          during the{" "}
                                                          <u>last 4 weeks</u>?
                                                        </p>
                                                        <p></p>
                                                        <div
                                                          class="imgContainer"
                                                          id="question4"
                                                        >
                                                          <div class="genericFormGroup">
                                                            {adultQuestionFive.map(
                                                              (obj) => (
                                                                <>
                                                                  <span class="formControl-radio">
                                                                    {" "}
                                                                    <input
                                                                      type="radio"
                                                                      id="adult-5-answer1"
                                                                      data-weightage="1"
                                                                      name="adult-5"
                                                                    />{" "}
                                                                  </span>
                                                                  <label
                                                                    onClick={this.handleQuestionFive.bind(
                                                                      this,
                                                                      obj
                                                                    )}
                                                                    style={{
                                                                      backgroundColor:
                                                                        adultFifthId ==
                                                                        obj.title
                                                                          ? "#358dbe"
                                                                          : "",

                                                                      color:
                                                                        adultFifthId ==
                                                                        obj.title
                                                                          ? "#FFFFFF"
                                                                          : "",
                                                                    }}
                                                                    for="adult-5-answer1"
                                                                  >
                                                                    {obj.title}
                                                                  </label>
                                                                </>
                                                              )
                                                            )}
                                                          </div>

                                                          <div
                                                            class="quizScoreBox"
                                                            id="question4score"
                                                          >
                                                            <p></p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="clear"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    {selectedFiveArry.length > 0 && (
                                      <div class="quiz quizResultContainer initialized">
                                        <div class="clear"></div>
                                        <div
                                          onClick={this.handlResult.bind(this)}
                                          class="component-content"
                                        >
                                          <button
                                            class="button generateScore"
                                            type="button"
                                          >
                                            See result
                                          </button>
                                        </div>
                                      </div>
                                    )}

                                    <div class="quiz initialized">
                                      <div class="quizOverlayContainer">
                                        <div class="errorQuizOverlay overlay-bg">
                                          <div class="overlay-content">
                                            <div
                                              class="overlay-close"
                                              tabindex="0"
                                              style={{ display: "block" }}
                                            ></div>
                                            <h4>
                                              Ups... Something went wrong!
                                            </h4>
                                            <p class="errorQuestion">
                                              Please check Question::
                                              <span></span>
                                            </p>
                                          </div>
                                        </div>
                                        <div class="resultQuizOverlay overlay-bg">
                                          <div class="overlay-content">
                                            <div
                                              class="overlay-close"
                                              tabindex="0"
                                              style={{ display: "block" }}
                                            ></div>
                                            <div class="overlayContainerDiv"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      id="questionContainer"
                                      class="quizHideBox"
                                    >
                                      <div class="quizRecommendationContainer">
                                        <span
                                          class="quizRecommendation"
                                          data-min-range="0"
                                          data-max-range="9999"
                                          data-url="/results.html"
                                        ></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="richText component section quiz-button-text even last col-xs-12">
                                  <div class="component-content">
                                    <div class="richText-content">
                                      <p>
                                        Please answer all the questions//See
                                        result
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="footer" class="col-xs-12">
                <div class="row">
                  <div class="layout-outer">
                    <div class="layout-inner">
                      <div class="col-xs-12 col-md-12 default-style">
                        <div
                          class="box component section default-style first odd last col-xs-12"
                          style={{ padding: 0 }}
                        >
                          <div class="component-content" id="0808245730">
                            <div class="paragraphSystem content">
                              <div class="snippetReference component section footer-section footer-section-legals even last col-xs-12 reference-footer-legals-2">
                                <div class="inner">
                                  <div class="component-content">
                                    <div class="richText component section footer-paragraphs odd">
                                      <div class="component-content">
                                        <div class="richText-content">
                                          <p>
                                            © 2023 My HealthCK. All rights
                                            reserved.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="Footer paragraphSystem"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </body>
      </div>
    );
  }
}

export default index;
