import React from 'react'
import ReactDOM from 'react-dom'
import './lib/reactifyCss';


const rootEl = document.getElementById('root')

let render = async () => {

    
    try {
        const MainApp = require('./App').default
        ReactDOM.render(<MainApp/>, rootEl)
    } catch (e) {
    }
}
if (module.hot) {
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default
        render(<NextApp />, rootEl)

    })
}

render()