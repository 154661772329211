import './App.css';

import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import App from './container/App'
import history from './history';


const MainApp = () => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <Router history={history}>
      <Switch>
        <Route path='/' component={App} />
      </Switch>
    </Router>
  </MuiPickersUtilsProvider>



)

export default MainApp
